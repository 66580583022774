import axios from 'axios';
import CONFIG from 'config';
import authService from 'redux/auth';
import { qaKpCmsIds } from 'redux/api/knowledgeAi/knowledgeAi';
import { getRandomString } from 'utils/string-mapper/string-mapper';
import { checkDurationAndReload, isAuthAttemptSet, setAuthAttemptTime } from 'utils/auth/auth';
export const ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
  STATUS: 'status',
  RETRY: 'retry',
  ERROR: 'error'
};

export const name = 'chat';

export const CHAT_SET_HISTORY_ID = 'CHAT_SET_HISTORY_ID';
export const CHAT_PENDING = 'CHAT_PENDING';
export const CHAT_REPLACE_MESSAGE = 'CHAT_REPLACE_MESSAGE';
export const CHAT_FAILURE = 'CHAT_FAILURE';
export const CHAT_SOURCES_SUCCESS = 'CHAT_SOURCES_SUCCESS';
export const CHAT_SOURCES_PENDING = 'CHAT_SOURCES_PENDING';
export const CHAT_SOURCES_FAILURE = 'CHAT_SOURCES_FAILURE';
export const RESET_CHAT_STATE = 'RESET_CHAT_STATE';
export const SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS = 'SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS';
export const CHAT_STREAM_CHUNK = 'CHAT_STREAM_CHUNK';
export const CHAT_STREAM_NEW_MESSAGE = 'CHAT_STREAM_NEW_MESSAGE';
export const CHAT_SET_QUERY = 'CHAT_SET_QUERY';
export const CHAT_ADD_MESSAGE = 'CHAT_ADD_MESSAGE';
export const CHAT_DELETE_LATEST_ASSISTANT_MESSAGE = 'CHAT_DELETE_LATEST_ASSISTANT_MESSAGE';
export const SET_FETCH_CONTROLLER = 'SET_FETCH_CONTROLLER';
export const NEW_ERROR_STATUS = 'NEW_ERROR_STATUS';
export const REMOVE_FETCH_CONTROLLER = 'REMOVE_FETCH_CONTROLLER';
export const SET_SELECTED_ENGINE = 'SET_SELECTED_ENGINE';
export const START_NEW_CHAT = 'START_NEW_CHAT';
export const CHAT_STREAM_COMPLETED = 'CHAT_STREAM_COMPLETED';
export const CHAT_SET_REQUEST_ID = 'CHAT_SET_REQUEST_ID';
export const SET_CHAT_HISTORY_MESSAGE = 'SET_CHAT_HISTORY_MESSAGE';
export const CHAT_STREAM_CLOSED = 'CHAT_STREAM_CLOSED';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  query: '',
  messages: [],
  hasSentInitialMessage: false,
  messageSources: {},
  fetchController: null,
  selectedEngine: 'gpt-4',
  chatHistoryId: null,
  isChatCompleted: false,
  isChatStreaming: false,
  isChatStreamClosed: false
};

const errorContinueMessage = {
  'role': 'user',
  'content': 'Your previous message was cut off due to an error. Please respond seamlessly from where you left off.'
};

const setHasSentInitialMessage = (hasSentInitialMessage) => {
  return { type: SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS, payload: hasSentInitialMessage };
};

const setChatMessageSources = (id, sources, documents) => (dispatch) => {
  try {
    if (!id) {
      throw new Error(`setChatMessageSources: Missing required parameters: id: ${id}, sources: ${sources}, documents: ${documents}`);
    }
    dispatch({ type: CHAT_SOURCES_PENDING, payload: { id } });

    const sourceDocs = [];
    if (sources && documents) {
      sources.forEach(source => {
        const doc = documents.find(doc => doc.kp_cms_id === source.id);
        if (doc) {
          sourceDocs.push(doc);
        } else {
          console.error('setChatMessageSources: Failed to find document, doc', id, 'sourceid', source.id);
        }
      });
      if (sourceDocs.length === 0) {
        throw new Error('setChatMessageSources: Failed to find any source documents');
      }
    }

    dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id, sources: sourceDocs, loading: sources.length === 0 } });
  } catch (error) {
    console.error('setChatMessageSources: Failed to set sources', error);
    dispatch({ type: CHAT_SOURCES_FAILURE, payload: { error, id } });
  }
};

const streamChat = (selectedEngine, onChunk, retry = false) => async (dispatch, getState) => {
  let response;
  dispatch({ type: CHAT_PENDING });
  const _query = getState().chat.query;
  const streamMessages = getState().chat.messages;
  if ((!_query || _query?.length === 0) && (!streamMessages || streamMessages?.length === 0)) {
    console.error('KNCHAT callChat: No query/messages to send');
    return null;
  }

  const requestId = getRandomString(20);
  dispatch(setRequestID(requestId));
  try {
    const _messages = streamMessages.filter(m => !!m.content && (m.role === ROLES.USER || m.role === ROLES.ASSISTANT)).map(message => {
      return {
        role: message.role,
        content: message.content
      };
    });

    const accessToken = await authService.getAccessToken();

    const headers = new Headers();
    headers.append('accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('x-api-key', CONFIG.X_API_KEY);

    const body_json = {
      'gen_options': {
        'max_tokens': 1600,
        'stream': true
      },
      'request_id': requestId,
      'consumer_id': 'KN',
      'engine': selectedEngine
    };
    if (getState().chat.chatHistoryId) {
      console.log('KNCHAT callChat: Using chatHistoryId', getState().chat.chatHistoryId);
      body_json.chat_history_id = getState().chat.chatHistoryId;
    }
    if (_query && _query.length > 0 && CONFIG.API_URL.GENAI_CHAT.includes('/v2/')) {
      body_json.query = retry ? errorContinueMessage.content.concat(',', _query) : _query;
    }
    else {
      body_json.messages = retry ? _messages.concat(errorContinueMessage) : _messages;
    }
    const body = JSON.stringify(body_json);

    const fetchController = new AbortController();
    const requestOptions = {
      method: 'POST',
      headers,
      body,
      redirect: 'follow',
      signal: fetchController.signal
    };
    dispatch({ type: SET_FETCH_CONTROLLER, payload: fetchController });
    const startTime = new Date();
    // using fetch as axios doesn't support this type of stream
    try {
      response = await fetch(CONFIG.API_URL.GENAI_CHAT, requestOptions);
      if (response.status === 403) {
        if (!isAuthAttemptSet()) setAuthAttemptTime();
        checkDurationAndReload(3);
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
    const reader = response.body.pipeThrough(new window.TextDecoderStream()).getReader();

    let boldMarkdown = '';
    let boldMarkdownOpen = false;
    let waitForBoldMarkdown = false;

    let contentCnt = 0;
    let chunkCnt = 0;

    let endedCleanly = false;
    let createdNewMessage = false;
    let shouldProcessMessage = true;

    let chatId = '';
    let processChunks = true;
    await dispatch({ type: CHAT_STREAM_COMPLETED, payload: false });
    await dispatch({ type: CHAT_STREAM_CLOSED, payload: false });
    while (processChunks) {
      const { value, done } = await reader.read();
      if (done) {
        // this stream closes here for short messages
        await dispatch({ type: CHAT_STREAM_COMPLETED, payload: true });
        await dispatch({ type: CHAT_STREAM_CLOSED, payload: true });
        console.warn(`KNCHAT done with ${contentCnt} chunks ${new Date() - startTime}ms; requestId: ${requestId};`);
        processChunks = false;
        break;
      }
      if (value) {
        // console.log('KNCHAT Received', new Date() - startTime, value);
        // expected format of value is "data: value\n\ndata: value\n\ndata: value"
        const chunks = value.split('\n\n').filter(c => c);

        let content = null;
        chunks.forEach(chunk => {
          chunkCnt++;
          // expected format of chunk is "data: value"
          const data = chunk.replace('data:', '').trim();
          // console.log('KNCHAT chunk', data);
          let json = {};
          try {
            json = data ? JSON.parse(data) : {};
            // we receive many chunks, but only chunks with choices[0].delta.content are chat messages
            content = json?.choices?.length > 0 ? json.choices[0].delta?.content : null;
            if (!chatId && json?.id) {
              chatId = json.id;
            }
          } catch (ex) {
            console.error(`KNCHAT failed to parse json. Attempting regex. error:${ex}; data:${data}; value: ${value}; requestId: ${requestId}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);
            // attempt to get content using regex
            let matches = data.match(/"content":\s*"([^"]*)"/i);
            if (matches?.length >= 1) {
              content = matches[1];
            } else {
              if (retry) {
                dispatch({ type: NEW_ERROR_STATUS, payload: { content: 'Apologies, we\'re experiencing high demand. Please try your request in a few minutes.', role: ROLES.ERROR } });
                throw new Error(`KNCHAT Second attempt failed to find content in chunk. error:${ex}; data:${data}; matches:${matches}; value: ${value}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);
              } else {
                console.error(`KNCHAT failed to find content in chunk. Retrying. error:${ex}; data:${data}; matches:${matches}; value: ${value}; requestId: ${requestId}; chatId: ${chatId}; chunkCnt: ${chunkCnt};`);

                // stop this API call and let's try again
                content = null;
                processChunks = false;
                endedCleanly = true; // yes really, if false it'll trigger an error which isnt accurate
                shouldProcessMessage = false;
                dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { content: 'I\'ve hit a snag - continuing in a moment.', role: ROLES.RETRY, processed: true, requestId } });
                console.log('KNCHAT run again');
                fetchController.abort('trying again');
                return dispatch(streamChat(selectedEngine, onChunk, true));
              }
            }
          }

          if (content) {
            if (contentCnt === 0) {
              console.warn(`KNCHAT time to first chunk ${new Date() - startTime}ms; requestId: ${requestId}; chatId: ${chatId};`);
            }
            let newContent = content;
            newContent = newContent.replace(/\n/g, '<br/>');
            if (newContent.match(/\*{1,2}/ig)) {
              // has a *, is it mardown for bold **
              boldMarkdown += content;
              waitForBoldMarkdown = true;
            } else if (waitForBoldMarkdown) {
              boldMarkdown += content;
              if (boldMarkdown.match(/\*\*/ig)) {
                if (!boldMarkdownOpen) {
                  newContent = boldMarkdown.replace(/\*\*/ig, '<b>');
                  boldMarkdownOpen = true;
                }
                else {
                  newContent = boldMarkdown.replace(/\*\*/ig, '</b>');
                  boldMarkdownOpen = false;
                }
                waitForBoldMarkdown = false;
                boldMarkdown = '';
              }
            }

            if (!waitForBoldMarkdown) {
              if (!createdNewMessage) {
                dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { role: ROLES.ASSISTANT, content: newContent, processed: false, requestId } });
                createdNewMessage = true;
              } else {
                dispatch({ type: CHAT_STREAM_CHUNK, payload: { content: newContent } });
                onChunk && onChunk();
              }
              // console.log('KNCHAT added', content);
            }
            contentCnt++;
          }
          if (json?.user_message) {
            console.log(`KNCHAT message: ${JSON.stringify(json)}; requestId: ${requestId}; chatId: ${chatId};`);
            dispatch({ type: CHAT_STREAM_NEW_MESSAGE, payload: { content: json.user_message, role: ROLES.STATUS, processed: true, requestId } });
            onChunk && onChunk();
            createdNewMessage = false;
          }
          if (json?.system_message) {
            switch (json.system_message) {
              case 'usage':
                console.warn(`KNCHAT usage: ${JSON.stringify(json.usage)}; requestId: ${requestId}; chatId: ${chatId};`);
                break;
              case 'END CHAT':
                processChunks = false;
                endedCleanly = true;
                dispatch({ type: CHAT_SET_HISTORY_ID, payload: json.chat_history_id });
                dispatch({ type: CHAT_STREAM_COMPLETED, payload: true });
                dispatch({ type: CHAT_STREAM_CLOSED, payload: true });
                // this stream closes here for long messages with sources
                break;
              case 'START INNER CHAT':
                dispatch({ type: CHAT_SET_HISTORY_ID, payload: json.chat_history_id });
                break;
              default:
                break;
            }
            console.warn(`KNCHAT system message: ${JSON.stringify(json)}; requestId: ${requestId}; chatId: ${chatId};`);
            if (json?.error) {
              shouldProcessMessage = false;
              dispatch({ type: NEW_ERROR_STATUS, payload: { content: 'Apologies, we\'re experiencing high demand. Please try your request in a few minutes.', role: ROLES.ERROR } });
              console.error(`KNCHAT system error: ${JSON.stringify(json)}; requestId: ${requestId}; chatId: ${chatId};`);
            }
          }
        });
      }
    }

    if (!endedCleanly) {
      await dispatch({ type: CHAT_STREAM_COMPLETED, payload: false });
      console.warn(`KNCHAT ended prematurely. chatId: ${chatId}; chunkCnt: ${chunkCnt}; contentCnt: ${contentCnt};`);
    }

    if (shouldProcessMessage) {
      const unprocessedMessages = getState().chat.messages.filter(m => !m.processed);
      unprocessedMessages.forEach(message => {
        const processedMessage = processMessage(message, requestId);
        dispatch(getSourcesFromSearch(processedMessage, requestId));
        dispatch({ type: CHAT_REPLACE_MESSAGE, payload: processedMessage });
      });
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      console.warn('KNCHAT callChat: API call aborted', error, 'requestId:', requestId);
    } else {
      console.error('KNCHAT callChat: API call failed', error, 'requestId:', requestId);
      dispatch({ type: CHAT_FAILURE, payload: error });
      return null;
    }
  }
};

const resetChatState = () => (dispatch) => {
  dispatch({ type: RESET_CHAT_STATE });
};

const processMessage = (message, requestId) => {
  // console.log('KNCHAT completed. Usage:', data.usage);
  if (message.role === 'function') return null;

  const sources = [];
  const slideNumberRegex = /slideno=(\d+)/g;
  const kpCmsIdRegex = /\/kp\/([a-f0-9-]+)/g;

  if (message.content.match(kpCmsIdRegex)) {
    let matchSlideNumber, matchKpCmsId;
    let cnt = 0;
    while ((matchSlideNumber = slideNumberRegex.exec(message.content)) && (matchKpCmsId = kpCmsIdRegex.exec(message.content))) {
      try {
        const slide = matchSlideNumber[1];
        let docid = matchKpCmsId[1];

        if (CONFIG.ENVIRONMENT !== 'prod' && CONFIG.ENVIRONMENT !== 'stg' && cnt < qaKpCmsIds.length) {
          console.error(`KNCHAT requestId ${requestId} swapped ${docid} (prod) for ${qaKpCmsIds.reverse()[cnt++]} (QA)`);
          docid = qaKpCmsIds.reverse()[cnt++];
        }

        sources.push({
          slide,
          id: docid,
        });


      } catch (ex) {
        console.error(`KNCHAT requestId ${requestId} Failed to parse sources in content`, ex);
        console.error(`KNCHAT  requestId ${requestId} content`, message.content);
        return null;
      }
    }
  }

  const uniqueSources = sources.reduce((acc, current) => acc.some(o => o.id === current.id && o.slide === current.slide) ? acc : [...acc, current], []);

  return {
    ...message,
    sources: uniqueSources
  };
};

const getSourcesFromSearch = (message, requestId) => async (dispatch) => {
  try {
    if (message.role === ROLES.USER) return;
    if (message.sources) {
      dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources: [], loading: true } });
      const query = message.sources.map(source => {
        if (source.id?.match(/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/i)) {
          return `kp_cms_id:${source.id}`;
        }
        console.error('KNCHAT getSourcesFromSearch: Invalid source id provided:', source.id, 'message:', JSON.stringify(message), 'requestId:', requestId);
        return null;
      }).filter(s => !!s).join(' OR ');

      console.warn('KNCHAT getSourcesFromSearch in env', CONFIG.ENVIRONMENT, 'query', query, 'requestId:', requestId);

      if (query) {
        const data = await axios.get(CONFIG.API_URL.MATERIAL(query));

        const sources = message.sources.map(source => {
          const doc = data.doc?.find(doc => doc.kpCmsId === source.id);
          if (doc) {
            return {
              ...doc,
              id: doc.kpCmsId,
              page: source.slide || 1,
            };
          } else {
            console.error('KNCHAT getSourcesFromSearch: Failed to find document, doc:', JSON.stringify(message), 'sourceid:', source.id, 'requestId:', requestId);
            return null;
          }
        }).filter(source => !!source);
        if (sources.length > 0) {
          dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources, loading: false } });
        } else {
          console.error('KNCHAT getSourcesFromSearch: Message had sources but no documents found in search', JSON.stringify(message), 'requestId:', requestId);
          dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources, loading: false } });
        }
      } else {
        console.error('KNCHAT getSourcesFromSearch: Message had sources but no valid IDs', JSON.stringify(message), 'requestId:', requestId);
        dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources: [], loading: false } });
      }
    } else {
      console.error('KNCHAT getSourcesFromSearch: No sources found on message', JSON.stringify(message), 'requestId:', requestId);
      dispatch({ type: CHAT_SOURCES_SUCCESS, payload: { id: message.id, sources: [], loading: false } });
    }
  } catch (ex) {
    console.error('KNCHAT getSourcesFromSearch: Error getting sources', JSON.stringify(message), ex, 'requestId:', requestId);
    dispatch({ type: CHAT_SOURCES_FAILURE, payload: { id: message.id, error: ex } });
  }

};

const setChatHistoryId = (chatHistoryId) => (dispatch) => {
  dispatch({ type: CHAT_SET_HISTORY_ID, payload: chatHistoryId });
};

const setRequestID = (requestId) => (dispatch) => {
  dispatch({ type: CHAT_SET_REQUEST_ID, payload: requestId });
};

const setQuery = (query) => (dispatch) => {
  dispatch({ type: CHAT_SET_QUERY, payload: query });
};

const addMessage = (message) => (dispatch) => {
  dispatch({ type: CHAT_ADD_MESSAGE, payload: message });
};

const abortFetch = () => (dispatch, getState) => {
  const fetchController = getState().chat.fetchController;
  if (fetchController !== null) {
    fetchController.abort('User clicked stop generating');
  }

  dispatch({ type: REMOVE_FETCH_CONTROLLER });
};

const setSelectedEngine = (engine) => (dispatch) => {
  dispatch({ type: SET_SELECTED_ENGINE, payload: engine });
};

const startNewChat = () => (dispatch) => {
  dispatch({ type: START_NEW_CHAT });
};

const updateChatMessages = (updatedMessages) => (dispatch) => {
  dispatch({ type: SET_CHAT_HISTORY_MESSAGE, payload: updatedMessages, loading: true });
};

export const actions = {
  streamChat,
  setHasSentInitialMessage,
  setChatMessageSources,
  resetChatState,
  setQuery,
  addMessage,
  abortFetch,
  setSelectedEngine,
  setChatHistoryId,
  startNewChat,
  processMessage,
  getSourcesFromSearch,
  updateChatMessages,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };
    case CHAT_REPLACE_MESSAGE:
      const newMessages = state.messages.map(m => {
        if (m.id === action.payload.id) {
          return action.payload;
        }
        return m;
      });
      console.log('KNCHAT replace message', action.payload, 'newMessages', newMessages);
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: newMessages
      };
    case CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message,
      };
    case SET_FETCH_CONTROLLER:
      return {
        ...state,
        fetchController: action.payload
      };
    case REMOVE_FETCH_CONTROLLER:
      return {
        ...state,
        loading: false,
        fetchController: null
      };
    case RESET_CHAT_STATE:
      if (state.fetchController !== null) {
        state.fetchController.abort('User cancelled/reset chat');
      }
      return initialState;
    case CHAT_ADD_MESSAGE:
      const replaceLastMessage = () => {
        const messagesCopy = [...state.messages];
        messagesCopy[messagesCopy.length - 1] = action.payload;
        return messagesCopy;
      };

      const isUser = action.payload.role === ROLES.USER;
      const isLastMessageAnError = state.messages[state.messages.length - 1]?.role === ROLES.ERROR;
      const id = state.messages.length > 1 ? state.messages[state.messages.length - 1].id + 1 : 0;
      return {
        ...state,
        messages: isUser && isLastMessageAnError
          ? replaceLastMessage()
          : [...state.messages, { ...action.payload, id }],
      };
    case CHAT_DELETE_LATEST_ASSISTANT_MESSAGE:
      const deleteLatestAssistantMessage = () => {
        const _messages = [...state.messages];
        for (let i = _messages.length - 1; i >= 0; i--) {
          if (_messages[i]?.role === ROLES.ASSISTANT) {
            _messages.splice(i, 1);
            break;
          }
        }
        return _messages;
      };
      return {
        ...state,
        messages: deleteLatestAssistantMessage()
      };
    case SET_HAS_SENT_INITIAL_MESSAGE_SUCCESS:
      return {
        ...state,
        hasSentInitialMessage: action.payload,
      };
    case CHAT_STREAM_NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, { ...action.payload, id: state.messages[state.messages.length - 1].id + 1 }],
      };
    case NEW_ERROR_STATUS:
      return {
        ...state,
        loading: false,
        messages: [
          ...state.messages,
          action.payload
        ]
      };
    case CHAT_STREAM_CHUNK:
      const appendToLastAssistantMessage = () => {
        const reversedMessages = [...state.messages].reverse();
        const lastAssistantMessage = reversedMessages.find(m => m.role === ROLES.ASSISTANT);
        if (lastAssistantMessage) {
          const indexToUpdate = reversedMessages.findIndex(m => m === lastAssistantMessage);
          reversedMessages[indexToUpdate].content += action.payload.content;
        }
        return reversedMessages.reverse();
      };

      return {
        ...state,
        isChatStreaming: true,
        messages: appendToLastAssistantMessage(),
      };
    case CHAT_STREAM_COMPLETED:
      return {
        ...state,
        isChatCompleted: action.payload,
      };
    case CHAT_STREAM_CLOSED:
      return {
        ...state,
        isChatStreaming: false,
        isChatStreamClosed: action.payload,
      };
    case CHAT_SOURCES_SUCCESS:
      return {
        ...state,
        messageSources: {
          ...state.messageSources,
          [action.payload.id]: {
            sources: action.payload.sources,
            loading: action.payload.loading,
            error: false,
            errorMessage: ''
          }
        },
      };
    case CHAT_SOURCES_FAILURE:
      return {
        ...state,
        messageSources: {
          ...state.messageSources,
          [action.payload.id]: {
            sources: state.messageSources[action.payload.id]?.sources,
            loading: false,
            error: true,
            errorMessage: action.payload.error
          }
        },
      };
    case SET_SELECTED_ENGINE:
      return {
        ...state,
        selectedEngine: action.payload
      };
    case CHAT_SET_QUERY:
      return {
        ...state,
        query: action.payload
      };
    case START_NEW_CHAT:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: [],
        messageSources: {}
      };
    case CHAT_SET_HISTORY_ID:
      return {
        ...state,
        chatHistoryId: action.payload
      };
    case CHAT_SET_REQUEST_ID:
      return {
        ...state,
        requestId: action.payload
      };
    case SET_CHAT_HISTORY_MESSAGE:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        messages: [...state.messages, ...action.payload.map(message => ({ ...message, id: message.id }))],
      };
    default:
      return state;
  }
};

