import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import CONFIG from 'config';
import { ACTION_PLAN, LABELS, SESSION_LIST } from 'utils/proposalBuilder/labels';
import { name as userReducerName, USER_STATUS } from 'redux/api/user/user';
import { selectors } from 'redux/api/proposalBuilder/chat/chat';

import Layout from 'containers/Layout/Layout';
import Loader from 'components/Loader/Loader';

import * as Styled from './proposalbuilder.style';

// Components
import { Sessions } from './Sessions/Sessions';
import { ProposalForm } from './ProposalForm/ProposalForm';
import { ActionPlan } from './ActionPlan/ActionPlan';
import { ProposalBuilderNaviSwitch } from './ProposalBuilderNaviSwitch/ProposalBuilderNaviSwitch';


export const ChatContainer = () => {
  const { hasAIAccessRetrievalStatus, hasAIAccess } = useSelector((state) => state[userReducerName]);
  const isChatLoading = useSelector(selectors.isLoading);
  const { FEATURE_TOGGLES: { SHOW_PROPOSALBUILDER }, PROPOSAL_DROPDOWN_OPTION, UI_URL: { BANNER } } = CONFIG;
  const { LISTING } = ACTION_PLAN;
  const { PROPOSALBUILDER_TITLE, CLICK_HERE, BETA } = LABELS;

  useEffect(() => {
    //Setting up page title
    document.title = PROPOSALBUILDER_TITLE;
  }, []);

  return (
    <Layout id="proposalbuilder" pageName="Proposal builder" mainId="proposalbuilder">
      {hasAIAccessRetrievalStatus === USER_STATUS.PENDING && 
        <Loader active />
      }
      {hasAIAccessRetrievalStatus === USER_STATUS.DONE && 
        <>
          { (!SHOW_PROPOSALBUILDER || !hasAIAccess) && <Redirect to={CONFIG.UI_URL.ERROR_WITH_SOURCE('404')} />}
          <Styled.ProposalBuilder>
            <div className="leftbar">
              <Sessions listItems={SESSION_LIST}/>
            </div>
            <div className="main">
              <div className="topheader">
                <Styled.Banner>
                  <span className='betaTag'>{BETA}</span>
                  <div className="bannerText">
                    <div>
                      {CONFIG.SEARCH.CHAT_BANNER.message1}
                    </div>
                    <div>
                      <a href={BANNER} target='_blank' rel="noreferrer">
                        {CLICK_HERE}
                      </a>
                      {CONFIG.SEARCH.CHAT_BANNER.message2}
                    </div>
                  </div>
                </Styled.Banner>
              </div>
              <div className="wrapper">
                <div className="leftsec">
                  <ProposalBuilderNaviSwitch defaultValue={PROPOSAL_DROPDOWN_OPTION.PROPOSALBUILDER.VALUE} />
                  <ProposalForm />
                </div>
                <div className="rightsec">
                  <ActionPlan listing={LISTING} disabled={isChatLoading} />
                </div>
              </div>
            </div>
          </Styled.ProposalBuilder>
        </>
      }
    </Layout>
  );
};

export default withRouter(ChatContainer);