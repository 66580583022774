import axios from 'axios';
import CONFIG from 'config';
import FILTERS from 'config-filters';
import { Exception } from 'sass';
import { CUSTOM_DATES, filterSorter, mapItem, setAppliedFilters } from 'utils/filters/filters';
import { flattenQuery, queryWithSearchParamsOnly, sortByNames } from 'utils/string-mapper/string-mapper';
export const name = 'experts';
export const API_EXPERTS_SEARCH_SUCCESS = 'API_EXPERTS_SEARCH_SUCCESS';
export const API_EXPERTS_SEARCH_PENDING = 'API_EXPERTS_SEARCH_PENDING';
export const API_EXPERTS_SEARCH_FAILURE = 'API_EXPERTS_SEARCH_FAILURE';
export const SET_EXPERTS_APPLIED_FILTERS_PENDING = 'SET_EXPERTS_APPLIED_FILTERS_PENDING';
export const SET_EXPERTS_APPLIED_FILTERS_SUCCESS = 'SET_EXPERTS_APPLIED_FILTERS_SUCCESS';
export const SET_EXPERTS_APPLIED_FILTERS_FAILED = 'SET_EXPERTS_APPLIED_FILTERS_FAILED';
export const SET_EXPERTS_ACTIVE_FILTER_INDEX = 'SET_EXPERTS_ACTIVE_FILTER_INDEX';
export const API_EXPERTS_LOAD_MORE_SUCCESS = 'API_EXPERTS_LOAD_MORE_SUCCESS';
export const API_EXPERTS_LOAD_MORE_PENDING = 'API_EXPERTS_LOAD_MORE_PENDING';
export const API_EXPERTS_LOAD_MORE_FAILURE = 'API_EXPERTS_LOAD_MORE_FAILURE';
export const CHANGE_SORTER = 'CHANGE_SORTER';
import { buildQuery, getAndMergePeopleData } from './utils';

const sources = [];
const initialState = {
  loading: true,
  error: false,
  errorMessage: '',
  empty: true,
  results: [],
  filters: [],
  previousSearch: { query: {} },
  lastSearch: { query: {} },
  totalCount: 0,
  pageCount: 0,
  currentPage: 0,
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFilterAndQueryParams: {},
  query: queryWithSearchParamsOnly(),
  activeFilter: ''
};

export const selectors = {
  getLoading: (state) => state[name].loading,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getEmpty: (state) => state[name].empty,
  getLastSearch: (state) => state[name].lastSearch,
  getPreviousSearch: (state) => state[name].previousSearch,
  getResults: (state) => state[name].results,
  getTotalCount: (state) => state[name].totalCount,
  getPageCount: (state) => state[name].pageCount,
  getResultsCurrentPage: (state) => state[name].currentPage,
  getActiveFilter: (state) => state[name].activeFilter,
  getAppliedFilters: (state) => state[name].appliedFilters,
  getAppliedQueryParams: (state) => state[name].appliedQueryParams,
  getFilters: (state) => state[name].filters,
  getQuery: (state) => state[name].query
};

export const actions = {
  buildQuery,
  expertSearch: (query, hasAIAccess) => async (dispatch) => {
    try {
      const newSource = axios.CancelToken.source();
      sources.push(newSource);

      const flattenedQuery = flattenQuery(query);
      const payloadQuery = buildQuery(query);
      const blankSearchCheckboxFilters = Object.entries(CONFIG.EXPERT_FILTERS.FILTER_BAR).filter(f => f[1].CHECKBOX_ONLY_FILTER && f[1].INCLUDE_IN_BLANK_SEARCH).map(f => f[1]);

      // if empty search/no search query and no filters applied,
      // set the pageSize to 0 to return no results but return filters.
      const isEmptySearch = payloadQuery.query.trim().length === 0;
      const noFiltersApplied = payloadQuery.filters.length === 0;
      const noCheckboxFiltersApplied = !Object.keys(payloadQuery).some(k => blankSearchCheckboxFilters.some(f => f.QUERY_PARAM === k && payloadQuery[k] === true));
      if (isEmptySearch && noFiltersApplied && noCheckboxFiltersApplied) {
        payloadQuery.pageSize = 0;
      }

      dispatch({ type: API_EXPERTS_SEARCH_PENDING, payload: { query: flattenedQuery } });

      const [dataNoFilters, dataOnlyFilters] = await Promise.all([
        axios.post(
          CONFIG.API_URL.EXPERT_SEARCH(hasAIAccess ? 'search' : 'keyword-search'),
          {
            ...payloadQuery,
            includeCCO: true,
            excludeFilters: true
          },
          {
            cancelToken: newSource.token,
            headers: { 'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY }
          }
        ),
        axios.post(
          CONFIG.API_URL.EXPERT_SEARCH(hasAIAccess ? 'search' : 'keyword-search'),
          {
            ...payloadQuery,
            includeCCO: true,
            excludeFilters: false,
            pageNumber: 0,
            pageSize: 0
          },
          {
            cancelToken: newSource.token,
            headers: { 'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY }
          }
        )
      ]);

      if (dataNoFilters?.error?.code) {
        throw new Exception(`${dataNoFilters.error.code} ${dataNoFilters.error.message}`);
      }

      if (dataOnlyFilters?.error?.code) {
        throw new Exception(`${dataOnlyFilters.error.code} ${dataOnlyFilters.error.message}`);
      }

      const results = dataNoFilters?.searchResults?.map((r) => ({
        ...r.profile,
        relevancyDetails: r.relevancyDetails,
        skills: r.profile.skills?.map(s => ({ ...s, tagName: s.topicPath?.replace(/\\\//g, '&#47;').replace(/>/g, '/'), originalTagName: s.topicName })),
        expertise: r.profile.expertise?.map(s => ({ ...s, tagName: s.topicPath?.replace(/\\\//g, '&#47;').replace(/>/g, '/'), originalTagName: s.topicName }))
      }));

      const joinedData = results?.length ? await getAndMergePeopleData(results, sources) : [];

      const filterNames = Object.values(FILTERS.EXPERT_FILTERS.FILTER_BAR).map(f => f.QUERY_PARAM);

      const filtersInDataOnlyFilters = dataOnlyFilters?.request ? dataOnlyFilters.request.filters : dataOnlyFilters?.filters;
      const filters = filtersInDataOnlyFilters?.map((f) => {
        const filterConfig = Object.values(FILTERS.EXPERT_FILTERS.FILTER_BAR).find((cf) => cf.QUERY_PARAM === f.filterName);
        const options = f.data?.filter(d => dataOnlyFilters.request ? d : d?.attributeCode)
          .map((value) => ({
            ...mapItem(value, null, filterConfig),
            children: value?.children?.map(c => mapItem(c, value.attributeCode, filterConfig))
          }))
          .sort(filterConfig?.SKIP_OPTIONS_SORT ? undefined : sortByNames);
        if (filterConfig?.DATE_RANGE) {
          const dateRangeOption = mapItem({
            attributeCode: CUSTOM_DATES,
            children: [],
            count: 0
          }, null, filterConfig);
          dateRangeOption.display = dateRangeOption.name;
          options.push(dateRangeOption);
        }
        return {
          ...f,
          ...filterConfig,
          parameterName: f.filterName,
          refinerValues: f.data,
          options
        };
      });

      const sortedFilters = filters?.filter((filter) => filterNames.includes(filter.filterName)).sort(filterSorter) || [];

      const payload = {
        ...dataNoFilters,
        filters: sortedFilters,
        query: flattenedQuery,
        pageCount: Math.ceil(dataNoFilters?.totalCount / (dataNoFilters?.pageSize || 20)),
        results: joinedData,
        empty: dataNoFilters?.count === 0,
        pageNumber: 0
      };

      dispatch({
        type: API_EXPERTS_SEARCH_SUCCESS,
        payload
      });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_EXPERTS_SEARCH_FAILURE, payload: error.toString() });
      throw error;
    }
  },
  setAppliedFilters: (filters) => setAppliedFilters(filters, CONFIG.EXPERT_FILTERS.FILTER_BAR, SET_EXPERTS_APPLIED_FILTERS_PENDING, SET_EXPERTS_APPLIED_FILTERS_SUCCESS, SET_EXPERTS_APPLIED_FILTERS_FAILED),
  setActiveFilter: (filterName) => async (dispatch) => dispatch({ type: SET_EXPERTS_ACTIVE_FILTER_INDEX, payload: filterName }),
  loadMoreFromSearch: (query, currentPage) => async (dispatch) => {
    try {
      const newSource = axios.CancelToken.source();
      sources.push(newSource);

      const flattenedQuery = flattenQuery(query);
      query = buildQuery(query, currentPage + 1);

      dispatch({
        type: API_EXPERTS_LOAD_MORE_PENDING,
        payload: {
          query: flattenQuery
        }
      });

      const dataNoFilters = await axios.post(
        CONFIG.API_URL.EXPERT_SEARCH('search'),
        {
          ...query,
          excludeFilters: true
        },
        {
          cancelToken: newSource.token,
          headers: { 'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY }
        },
      );

      const results = dataNoFilters?.searchResults?.map((r) => ({
        ...r.profile,
        relevancyDetails: r.relevancyDetails,
        skills: r.profile.skills?.map(s => ({ ...s, tagName: s.topicPath?.replace(/\\\//g, '&#47;').replace(/>/g, '/'), originalTagName: s.topicName })),
        expertise: r.profile.expertise?.map(s => ({ ...s, tagName: s.topicPath?.replace(/\\\//g, '&#47;').replace(/>/g, '/'), originalTagName: s.topicName }))
      }));

      const joinedData = results?.length ?
        await getAndMergePeopleData(results, sources) : [];

      const payload = {
        ...dataNoFilters,
        query: flattenedQuery,
        pageCount: Math.ceil(dataNoFilters.totalCount / (dataNoFilters?.pageSize || 20)),
        results: joinedData,
        empty: dataNoFilters.count === 0,
        pageNumber: currentPage + 1
      };

      dispatch({ type: API_EXPERTS_LOAD_MORE_SUCCESS, payload });
    } catch (error) {
      if (error.cancelled) return;
      dispatch({ type: API_EXPERTS_LOAD_MORE_FAILURE, payload: error.toString() });
      throw error;
    }
  },
  changeSorter: (payload) => async (dispatch) => dispatch({ type: CHANGE_SORTER, payload }),
  getFilterTypeAhead: async (fieldName, payload) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);
    return axios.post(
      CONFIG.API_URL.EXPERT_SEARCH(`search/${fieldName}-filter-type-ahead`),
      payload,
      {
        cancelToken: newSource.token,
        headers: { 'x-api-key': CONFIG.EXPERT_SEARCH_X_API_KEY }
      },
    );
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case API_EXPERTS_SEARCH_PENDING:
      return {
        ...state,
        loading: true,
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_EXPERTS_SEARCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
        currentPage: action.payload?.pageNumber,
        suggestion: action.payload.suggestion,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_EXPERTS_SEARCH_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.payload
      };
    case SET_EXPERTS_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_EXPERTS_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case SET_EXPERTS_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case SET_EXPERTS_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case API_EXPERTS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        filters: state.filters,
        loading: false,
        error: false,
        currentPage: action.payload?.pageNumber,
        results: state.results.concat(action.payload.results),
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          }
        }
      };
    case API_EXPERTS_LOAD_MORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case API_EXPERTS_LOAD_MORE_PENDING:
      return {
        ...state,
        error: false
      };
    case CHANGE_SORTER:
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.EXPERT_QUERY_PARAMS.SORTING_ORDER]: action.payload
        }
      };
    default:
      return state;
  }
};