import axios from 'axios';
import CONFIG from 'config';
import * as utils from '@kdshared/okta-utils';
import { setAppliedFilters } from 'utils/filters/filters';

import { currentQueryStringToObjArrays, cleanNulls, flattenQuery, queryWithSearchParamsOnly } from 'utils/string-mapper/string-mapper';

export const API_PEOPLE_RESULTS_PENDING = 'API_PEOPLE_RESULTS_PENDING';
export const API_PEOPLE_RESULTS_FAILURE = 'API_PEOPLE_RESULTS_FAILURE';
export const API_PEOPLE_RESULTS_SUCCESS = 'API_PEOPLE_RESULTS_SUCCESS';
export const API_PEOPLE_RESULTS_CANCELLED = 'API_PEOPLE_RESULTS_CANCELLED';
export const API_PEOPLE_TYPE_AHEAD_RESULTS_PENDING = 'API_PEOPLE_TYPE_AHEAD_RESULTS_PENDING';
export const API_PEOPLE_TYPE_AHEAD_RESULTS_SUCCESS = 'API_PEOPLE_TYPE_AHEAD_RESULTS_SUCCESS';
export const API_PEOPLE_TYPE_AHEAD_RESULTS_FAILURE = 'API_PEOPLE_TYPE_AHEAD_RESULTS_FAILURE';
export const API_PEOPLE_AUTHOR_RESULTS_PENDING = 'API_PEOPLE_AUTHOR_RESULTS_PENDING';
export const API_PEOPLE_AUTHOR_RESULTS_SUCCESS = 'API_PEOPLE_AUTHOR_RESULTS_SUCCESS';
export const API_PEOPLE_AUTHOR_RESULTS_FAILURE = 'API_PEOPLE_AUTHOR_RESULTS_FAILURE';
export const API_PEOPLE_LOCATION_SUCCESS = 'API_PEOPLE_LOCATION_SUCCESS';
export const SET_PEOPLE_ACTIVE_FILTER_INDEX = 'SET_PEOPLE_ACTIVE_FILTER_INDEX';
export const SET_PEOPLE_APPLIED_FILTERS_PENDING = 'SET_PEOPLE_APPLIED_FILTERS_PENDING';
export const SET_PEOPLE_APPLIED_FILTERS_SUCCESS = 'SET_PEOPLE_APPLIED_FILTERS_SUCCESS';
export const SET_PEOPLE_APPLIED_FILTERS_FAILED = 'SET_PEOPLE_APPLIED_FILTERS_FAILED';
export const UPDATE_APPLIED_FILTERS_PENDING = 'UPDATE_APPLIED_FILTERS_PENDING';
export const UPDATE_APPLIED_FILTERS_SUCCESS = 'UPDATE_APPLIED_FILTERS_SUCCESS';
export const API_PEOPLE_RESULTS_LOAD_MORE_PENDING = 'API_PEOPLE_RESULTS_LOAD_MORE_PENDING';
export const API_PEOPLE_RESULTS_LOAD_MORE_FAILURE = 'API_PEOPLE_RESULTS_LOAD_MORE_FAILURE';
export const API_PEOPLE_RESULTS_LOAD_MORE_SUCCESS = 'API_PEOPLE_RESULTS_LOAD_MORE_SUCCESS';
export const SET_REDIRECT_TO_LINK = 'SET_REDIRECT_TO_LINK';
export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';
export const UPDATE_PEOPLE_FILTERS = 'UPDATE_PEOPLE_FILTERS';

export const name = 'peopleResults';

const sources = [];
const initialState = {
  results: [],
  typeAheadResults: [],
  authorResults: [],
  typeAheadloading: false,
  loading: true,
  initialStateSet: true,
  authorsLoading: false,
  loadMorePending: false,
  empty: true,
  peopleResultsError: false,
  error: false,
  errorMessage: '',
  totalCount: 0,
  pageCount: 0,
  currentPage: CONFIG.DEFAULT_CURRENT_PAGE,
  activeFilter: '',
  filters: [],
  appliedFilters: {}, // object ready for qs to convert to a string in string-mapper.js
  appliedFiltersError: false,
  appliedFiltersErrorMsg: '',
  appliedQueryParams: {}, // object ready for qs to convert to a string in string-mapper.js
  locations: [],
  previousSearch: { query: { } },
  lastSearch: { query: { } },
  redirectToLink: false,
  appliedFiltersArray: [],
  query: queryWithSearchParamsOnly(),
  autoCorrectMode: true,
  autoCorrect: {
    isSearchTermCorrected: false,
    correctedSearchTerm: ''
  }
};

export const selectors = {
  getResults: (state) => state[name].results,
  getResultsFiltered: (state) => state[name].results,
  getTypeAheadResults: (state) => state[name].typeAheadResults,
  getAuthorResults: (state) => state[name].authorResults,
  getLoading: (state) => state[name].loading,
  getLoadMorePending: (state) => state[name].loadMorePending,
  getLocationLoading: (state) => state[name].locationsLoading,
  getEmpty: (state) => state[name].empty,
  getError: (state) => state[name].error,
  getErrorMessage: (state) => state[name].errorMessage,
  getPeopleResultsError: (state) => state[name].peopleResultsError,
  getTotalCount: (state) => state[name].totalCount,
  getPageCount: (state) => state[name].pageCount,
  getActiveFilter: (state) => state[name].activeFilter,
  getFilters: (state) => state[name].filters,
  getLocations: (state) => state[name].locations,
  getAppliedFilters: (state) => state[name].appliedFilters,
  getAppliedQueryParams: (state) => state[name].appliedQueryParams,
  getLastSearch: (state) => state[name].lastSearch,
  getPreviousSearch: (state) => state[name].previousSearch,
  getRedirectToLink: (state) => state[name].redirectToLink,
  getInitialStateSet: (state) => state[name].initialStateSet,
  getQuery: (state) => state[name].query
};

const getMappingValue = (filterConfig, text, peopleResultsState) => {
  let newName;
  if (filterConfig.MAPPING) {
    newName = filterConfig.MAPPING.find((rec) => rec.id.toLowerCase() === text.toLowerCase());
  }
  if (filterConfig.MAPPING_STATE) {
    newName = peopleResultsState[filterConfig.MAPPING_STATE].find((rec) => rec.id === text);
  }
  return newName ? newName.name : text;
};

const updateFilterObj = (filter, peopleResultsState) => {
  const filterConfig = Object.values(CONFIG.PEOPLE_FILTERS).find((f) => f.QUERY_PARAM === filter.field);
  const filterValues = [];

  if (filterConfig.STATIC_OPTIONS) {
    filterConfig.MAPPING.forEach((rec) => {
      const filterOption = filter?.options?.find((option) => option.key.toLowerCase() === rec.id.toLowerCase());
      if (!filterValues.some((fv) => fv.key.toLowerCase() === rec.id.toLowerCase())) {
        // adding in the other filter for 'Alumni' to display in the dropdown
        filterValues.push({
          key: rec.id,
          count: typeof filterOption?.count === 'string' ? +filterOption.count : 0,
          name: rec.id,
          checked: false,
          field: encodeURIComponent(rec.id),
          path: `/${rec.id}/`,
          display: `${rec.name} (${filterOption ? filterOption.count : 0})`,
          filter: { ...filterConfig }
        });
      }
    });
  } else {
    filter.options.forEach((value) => { // value is {key:'', count: ''}
      const valueName = getMappingValue(filterConfig, value.key, peopleResultsState);

      filterValues.push({
        ...value,
        count: typeof value.count === 'string' ? +value.count : 0,
        name: value.key,
        checked: false,
        field: encodeURIComponent(value.key),
        path: `/${value.key}/`,
        display: `${valueName} (${value.count})`
      });
    });
  }

  return {
    ...filter,
    ...filterConfig,
    options: filterValues
  };
};

const setAdditionalInfo = (employee) => {
  const assistantObj = employee.assistants && employee.assistants.length > 0 && {
    ...employee.assistants[0],
    isAssistant: true,
    isActive: employee.assistants[0].alumni === 'NO',
    hasSlackAccount: !!employee.assistants[0].slackId,
    slackDirectMessageUrl: CONFIG.SLACK_URL(employee.assistants[0].slackId),
    businessTitle: employee.assistants[0].title,
  };
  const other = {
    isActive: employee.alumni === 'NO',
    hasSlackAccount: !!employee.slackId,
    slackDirectMessageUrl: CONFIG.SLACK_URL(employee.slackId),
    businessTitle: employee.title,
    isAssistant: false,
  };
  const employeeObj = cleanNulls(employee);
  const assistant = assistantObj ? cleanNulls(assistantObj) : {};
  return {
    ...employeeObj,
    assistants: null,
    ...other,
    assistant
  };
};

const buildFilterString = (searchTerm, appliedFilters) => {
  let filterString = '';
  // replace white space with asterisks
  const searchTermWithAsterisk = searchTerm?.replace(new RegExp(/\s/, 'igm'), '* *');
  // trim white spaces
  const cleanSearchTerm = searchTerm?.replace(new RegExp(/\s/, 'igm'), '');

  // See https://regexpattern.com/email-address/
  const regExEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isSearchTermEmail = regExEmail.test(searchTerm);

  // title, location, phone and mobile wont have the asterisks and location, searchTerm should retrieve only exact matches
  const filterKeywordFieldsPartialWordMatch = ['firstName', 'lastName', 'name', 'email'];
  const filterKeywordFieldsMatch = ['title'];
  const filterKeywordFieldsExactMatch = ['location', 'searchTerm'];
  const filterEmailExactMatch = ['email'];
  const filterKeywordFieldsContainsWordMatch = ['phone', 'mobile'];

  filterString = // spacing is weird but this allows us to read it clearer when it's all concatenated
    isSearchTermEmail ?
      `                     {
                        logicOperator: OR
                        subFilters: [
                          ${filterEmailExactMatch.map((k) =>
    `{ valueFilter: { field: "${k}=${searchTerm}" } }`)}
                           ]
                          }\n` :
      `                     {
                        logicOperator: OR
                        subFilters: [
                          ${filterKeywordFieldsPartialWordMatch.map((k) =>
    `{ valueFilter: { field: "${k}=*${searchTermWithAsterisk}*" } }`).join('')}
                          ${filterKeywordFieldsMatch.map((k) =>
    `{ valueFilter: { field: "${k}=${searchTerm}*" } }`)}
                          ${filterKeywordFieldsExactMatch.map((k) =>
    `{ valueFilter: { field: "${k}=${searchTerm}" } }`).join('')}
                          ${filterKeywordFieldsContainsWordMatch.map((k) =>
    `{ valueFilter: { field: "${k}=*${cleanSearchTerm}*" } }`).join('')}
                           ]
                          }\n`;

  Object.keys(appliedFilters).forEach((f) => {
    const values = appliedFilters[f];
    filterString += // spacing is weird but this allows us to read it clearer when it's all concatenated
      `                     {
                        logicOperator: OR
                        subFilters: [
                          ${values.map((v) =>
    `{ valueFilter: { field: "${f}=${v.path.replaceAll('/', '')}" } }`).join('')}
                        ]
                      }\n`;
  });

  filterString += // spacing is weird but this allows us to read it clearer when it's all concatenated
    `                       {
                        logicOperator: NOT
                        subFilters: [
      { valueFilter: { field:"name=Anonymized"}}
                        ]
                    }\n`;

  filterString += // spacing is weird but this allows us to read it clearer when it's all concatenated
    `                       {
                        logicOperator: NOT
                        subFilters: [
      { valueFilter: { field:"status=Contract-Terminated"}}
                        ]
                    }\n`;

  filterString += // spacing is weird but this allows us to read it clearer when it's all concatenated
    `                       {
                        logicOperator: NOT
                        subFilters: [
      { valueFilter: { field:"status=Temporary-Terminated"}}
                        ]
                    }\n`;

  if (filterString) {
    filterString = // spacing is weird but this allows us to read it clearer when it's all concatenated
      `compositeFilter: {
                    logicOperator: AND
                    subFilters: [
 ${filterString}                    ]
                  }`;
  }

  return filterString;
};

const PEOPLE_SEARCH_LIMIT = 60;
const PEOPLE_TYPEAHEAD_SEARCH_LIMIT = 8;

export const configPeopleSearchPayload = function(searchTerm, offset, appliedFilters, sort, fuzzySearch = false) {
  let cleanTerm = searchTerm ? decodeURIComponent(fuzzySearch ? `${searchTerm}~` : searchTerm) : '';
  cleanTerm = cleanTerm.replace(/\s-\s|\s\|\s|\s&\s/ig, ' ').replace(new RegExp(/[+]/, 'igm'), '');
  const qlFilters = buildFilterString(cleanTerm, appliedFilters);

  return {
    query: `
        query {
          searchFilter(
            aliasMatching:["title"]
            ${qlFilters}
            resultFilters:["jobPosition", "hostOfficeId", "hostOfficeRegion", "alumni"]
            dataSet:BCG_ALL
            limit:${PEOPLE_SEARCH_LIMIT}
            offset:${offset}
            sortBy:[${sort.field}]
            sortOrder: ${sort.direction}
          ){
            totalCount
            employees {
                id
                profilePicture
                name
                firstName
                lastName
                title
                hostOfficeLocation {
                    id
                    name
                    timezone
                }
                hostOfficeRegion
                phone
                mobile
                email
                slackId
                lastUpdated
                status
                alumni
                assistants {
                  id
                  name
                  firstName
                  lastName
                  jobFunction
                  title
                  hostOfficeLocation {
                    name
                  }
                  hostOfficeRegion
                  phone
                  mobile
                  email
                  slackId
                  lastUpdated
                  status
                  alumni
                }
            }
            searchFilters{
                field
                options: filters{
                    key
                    count
                }
            }
          }
        }`
  };
};

export const actions = {
  buildFilterString,
  createUpdatedEmployeeWithAdditionalInfo: setAdditionalInfo,
  peopleSearch: (searchTerm, offset = 0, defaultFilters = null, sort = { field: '"firstName", "lastName"', direction: 'ASC' }) => async (dispatch, getState) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);

    const appliedFilters = getState()?.peopleResults?.appliedFilters;
    const flatAppliedFilters = appliedFilters ? flattenQuery(appliedFilters) : {};

    if (offset === 0) {
      dispatch({
        type: API_PEOPLE_RESULTS_PENDING,
        payload: {
          query: {
            query: searchTerm,
            ...flatAppliedFilters
          },
          sort,
          offset
        }
      });
    } else {
      dispatch({
        type: API_PEOPLE_RESULTS_LOAD_MORE_PENDING,
        payload: {
          query: {
            query: searchTerm,
            ...flatAppliedFilters
          },
          sort,
          offset
        }
      });
    }

    try {
      if (defaultFilters && defaultFilters !== appliedFilters) {
        setAppliedFilters(defaultFilters, CONFIG.PEOPLE_FILTERS, SET_PEOPLE_APPLIED_FILTERS_PENDING, SET_PEOPLE_APPLIED_FILTERS_SUCCESS, SET_PEOPLE_APPLIED_FILTERS_FAILED);
      }

      let payload = configPeopleSearchPayload(searchTerm, offset, appliedFilters, sort);

      let data = await axios
        .post(
          CONFIG.API_URL.PEOPLE,
          payload,
          {
            headers: {
              'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
              psId: utils.getPsId()
            }
          }
        );

      if (data?.data?.searchFilter?.totalCount === 0) {
        payload = configPeopleSearchPayload(searchTerm, offset, appliedFilters, sort, true);

        data = await axios
          .post(
            CONFIG.API_URL.PEOPLE,
            payload,
            {
              headers: {
                'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
                psId: utils.getPsId()
              }
            }
          );
      }

      let filters = data.data.searchFilter.searchFilters.map((filter) => updateFilterObj(filter, getState().peopleResults));
      filters = filters.sort((a, b) => {
        // SORT_ORDER isn't always defined, leading to unexpected sort orders.
        let sortOrder = 0;
        if (a.SORT_ORDER && b.SORT_ORDER) {
          sortOrder = a.SORT_ORDER > b.SORT_ORDER ? 1 : -1;
        }

        return sortOrder;
      });

      const peopleData = data.data.searchFilter.employees.map((employee) => setAdditionalInfo(employee));
      if (offset === 0) {
        dispatch({
          type: API_PEOPLE_RESULTS_SUCCESS,
          payload: {
            ...data.data,
            peopleData,
            filters,
            query: {
              query: searchTerm,
              ...flatAppliedFilters
            },
            sort,
            offset
          } });
      } else {
        dispatch({
          type: API_PEOPLE_RESULTS_LOAD_MORE_SUCCESS,
          payload: {
            ...data.data,
            peopleData,
            filters,
            query: {
              query: searchTerm,
              ...flatAppliedFilters
            },
            sort,
            offset
          }
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      if (offset === 0) dispatch({ type: API_PEOPLE_RESULTS_FAILURE, payload: error.message });
      else dispatch({ type: API_PEOPLE_RESULTS_LOAD_MORE_FAILURE, payload: error.message });
    }
  },
  updatePeopleFilters: () => async (dispatch, getState) => {
    let filters = getState().peopleResults.filters?.map((filter) => updateFilterObj(filter, getState().peopleResults));
    dispatch({ type: UPDATE_PEOPLE_FILTERS, payload: filters });
  },
  peopleTypeAheadSearch: async (searchTerm) => {
    const cleanTerm = searchTerm.replace('%20', ' '); // temp fix until we determine what to do with special characters
    return axios
      .post(
        CONFIG.API_URL.PEOPLE,
        {
          query: `
              query {
                searchFilter(
                  compositeFilter: {
                    logicOperator: AND
                    subFilters: [
                      {
                        logicOperator: OR
                        subFilters: [
                          { valueFilter: { field: "firstName=${cleanTerm}*" } }
                          { valueFilter: { field: "lastName=${cleanTerm}*" } }
                          { valueFilter: { field: "name=${cleanTerm}*" } }
                          { valueFilter: { field: "location=${cleanTerm}*" } }
                          { valueFilter: { field: "phone=${cleanTerm}*" } }
                          { valueFilter: { field: "mobile=${cleanTerm}*" } }
                          ]
                      }
                      {
                        logicOperator: NOT
                        subFilters: [
                            { valueFilter: { field:"name=Anonymized"}}
                        ]
                      }
                      {
                        logicOperator: NOT
                        subFilters: [
                            { valueFilter: { field:"status=Contract-Terminated"}}
                        ]
                      }
                      {
                        logicOperator: NOT
                        subFilters: [
                            { valueFilter: { field:"status=Temporary-Terminated"}}
                        ]
                      }
                    ]
                  }
                  dataSet:BCG_ALL
                  limit:${PEOPLE_TYPEAHEAD_SEARCH_LIMIT}
                  offset:0
                ){
                  employees {
                      id
                      firstName
                      lastName
                      title
                      hostOfficeLocation {
                        name
                      }
                  }
                }
              }`
        },
        {
          headers: {
            'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
            psId: utils.getPsId()
          }
        }
      );
  },
  authorSearch: (authorIdArray) => async (dispatch) => {
    const newSource = axios.CancelToken.source();
    sources.push(newSource);
    dispatch({ type: API_PEOPLE_AUTHOR_RESULTS_PENDING });
    try {
      const data = await axios
        .post(
          CONFIG.API_URL.PEOPLE,
          {
            query: `
            query {
              employees(ids: [${authorIdArray.join()}]) {
               id
               firstName
               lastName
               title
               email
               slackId
               hostOfficeLocation {
                 name
               }
               profilePicture
               lastUpdated
               alumni
               assistants {
                   id
                   name
                   firstName
                   lastName
               }
             }
             }`
          },
          {
            headers: {
              'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
              psId: utils.getPsId()
            }
          }
        );

      const peopleData = data.data.employees.map((employee) => setAdditionalInfo(employee));
      peopleData.map((person) => (
        dispatch({ type: API_PEOPLE_AUTHOR_RESULTS_SUCCESS, payload: { id: person.id, item: person } })
      ));
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      dispatch({ type: API_PEOPLE_AUTHOR_RESULTS_FAILURE, payload: error.message });
    }
  },
  locationSearch: () => async (dispatch, getState) => {
    if (!getState().peopleResults.locations || getState().peopleResults.locations.length === 0) {
      const newSource = axios.CancelToken.source();
      sources.push(newSource);

      try {
        const data = await axios
          .post(CONFIG.API_URL.LOCATIONS,
            {
              query: `
              query {
                locations {
                  id
                  name
                }
              }
              `
            },
            {
              headers: {
                'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
                psid: utils.getPsId()
              }
            });

        dispatch({ type: API_PEOPLE_LOCATION_SUCCESS, payload: { items: data.data.locations } });
      } catch (error) {
        // eslint-disable-next-line
        console.error('locationSearch', error);
        // nothing to dispatch or handle, code handles an empty locations just fine
      }
    }
  },
  setActiveFilter: (index) => ({ type: SET_PEOPLE_ACTIVE_FILTER_INDEX, payload: index }),
  setAppliedFilters: (queryParams) => (setAppliedFilters(queryParams, CONFIG.PEOPLE_FILTERS, SET_PEOPLE_APPLIED_FILTERS_PENDING, SET_PEOPLE_APPLIED_FILTERS_SUCCESS, SET_PEOPLE_APPLIED_FILTERS_FAILED)),
  cancel: () => {
    sources.forEach((source) => source.cancel());
    return { type: API_PEOPLE_RESULTS_CANCELLED };
  },

  setRedirectToLink: (redirectToLink) => (dispatch) => {
    dispatch({
      type: SET_REDIRECT_TO_LINK,
      payload: redirectToLink
    });
  },
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_SEARCH_TERM: // dispatched from type-ahead
      return {
        ...state,
        query: {
          ...state.query,
          [CONFIG.QUERY_PARAMS.QUERY]: action.payload
        }
      };
    case API_PEOPLE_RESULTS_PENDING:
      return {
        ...state,
        loading: true,
        initialStateSet: false,
        empty: false,
        error: false,
        errorMessage: '',
        previousSearch: state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          },
          sort: action.payload.sort,
          offset: action.payload.offset
        }
      };
    case API_PEOPLE_RESULTS_LOAD_MORE_PENDING:
      return {
        ...state,
        loadMorePending: true,
        empty: false,
        peopleResultsError: false,
        errorMessage: ''
      };
    case API_PEOPLE_RESULTS_FAILURE:
    case API_PEOPLE_RESULTS_LOAD_MORE_FAILURE:
      return {
        ...state,
        loading: false,
        initialStateSet: false,
        peopleResultsError: true,
        loadMorePending: false,
        error: true,
        errorMessage: action.payload
      };
    case API_PEOPLE_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        initialStateSet: false,
        peopleResultsError: false,
        empty: action.payload.searchFilter.employees.length <= 0,
        results: action.payload.peopleData,
        totalCount: action.payload.searchFilter.totalCount,
        currentPage: CONFIG.DEFAULT_CURRENT_PAGE,
        pageCount: Math.ceil(action.payload.searchFilter.totalCount / PEOPLE_SEARCH_LIMIT),
        filters: action.payload.filters,
        previousSearch: state.previousSearch.query?.query ? state.previousSearch : state.lastSearch,
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          },
          sort: action.payload.sort,
          offset: action.payload.offset
        },
        redirectToLink: action.payload.searchFilter.totalCount === 1
      };
    case API_PEOPLE_RESULTS_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadMorePending: false,
        empty: action.payload.searchFilter.employees.length <= 0,
        results: state.results.concat(action.payload.peopleData),
        totalCount: action.payload.searchFilter.totalCount,
        currentPage: Math.ceil((state.results.length + action.payload.peopleData.length) / PEOPLE_SEARCH_LIMIT),
        pageCount: Math.ceil(action.payload.searchFilter.totalCount / PEOPLE_SEARCH_LIMIT),
        lastSearch: {
          query: {
            ...action.payload.query,
            emptyQuery: !action.payload.query?.query?.trim().length
          },
          sort: action.payload.sort,
          offset: action.payload.offset
        },
        filters: action.payload.filters,
      };
    case API_PEOPLE_TYPE_AHEAD_RESULTS_PENDING:
      return {
        ...state,
        typeAheadloading: true,
        empty: false,
        error: false,
        errorMessage: ''
      };
    case API_PEOPLE_TYPE_AHEAD_RESULTS_FAILURE:
      return {
        ...state,
        typeAheadloading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_PEOPLE_TYPE_AHEAD_RESULTS_SUCCESS:
      return {
        ...state,
        typeAheadloading: false,
        empty: action.payload.items.length <= 0,
        typeAheadResults: action.payload.items
      };
    case API_PEOPLE_AUTHOR_RESULTS_PENDING:
      return {
        ...state,
        authorsLoading: true,
        empty: false,
        error: false,
        errorMessage: ''
      };
    case API_PEOPLE_AUTHOR_RESULTS_FAILURE:
      return {
        ...state,
        authorsLoading: false,
        error: true,
        errorMessage: action.payload
      };
    case API_PEOPLE_AUTHOR_RESULTS_SUCCESS:
      return {
        ...state,
        authorsLoading: false,
        authorResults: {
          ...state.authorResults,
          [action.payload.id]: action.payload.item
        }
      };
    case API_PEOPLE_LOCATION_SUCCESS:
      return {
        ...state,
        empty: action.payload.items.length <= 0,
        locations: action.payload.items,
      };
    case SET_PEOPLE_ACTIVE_FILTER_INDEX:
      return {
        ...state,
        activeFilter: action.payload
      };
    case SET_PEOPLE_APPLIED_FILTERS_PENDING:
      return {
        ...state,
        appliedFilters: {}
      };
    case SET_PEOPLE_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters,
        appliedQueryParams: action.payload.appliedQueryParams,
        appliedFilterAndQueryParams: action.payload.appliedFilterAndQueryParams
      };
    case SET_PEOPLE_APPLIED_FILTERS_FAILED:
      return {
        ...state,
        appliedFiltersError: true,
        appliedFiltersErrorMsg: action.payload.errorMessage
      };
    case UPDATE_APPLIED_FILTERS_SUCCESS:
      return {
        ...state,
        appliedFilters: action.payload.appliedFilters
      };
    case SET_REDIRECT_TO_LINK:
      return {
        ...state,
        redirectToLink: action.payload
      };
    case UPDATE_PEOPLE_FILTERS:
      return {
        ...state,
        filters: action.payload
      };
    default:
      return state;
  }
}