// Description: All the utility functions related to chat history are defined here.

export const addNewChatToChatHistory = (chatData) => {
  // Check if there is Today Chat History exist or not, modified_date is in ISO format
  // If not then add newChatObj with created_date and modified_date is current date in ISO format.
  // If yes then add newChatObj with created_date is current date in ISO format and modified_date is the same as the last chat history.
  let newChatObj = {
    request_id: '',
    consumer_id: '',
    chat_history_id: null,
    continuation: 1,
    customer_id: 1,
    user_hrid: '',
    created_date: '',
    modified_date: '',
    title: 'New Chat',
    message: [],
    metadata: '',
    isNewChat: true,
  };
  const today = new Date();
  const todayInISO = today.toISOString().split('T')[0];
  const todayChatHistory = chatData['Today']?.find(
    (chat) => chat.modified_date.split('T')[0] === todayInISO,
  );
  if (
    !todayChatHistory ||
    todayChatHistory?.title !== 'New Chat'
  ) {
    newChatObj = {
      ...newChatObj,
      modified_date: new Date().toISOString(),
      created_date: new Date().toISOString(),
    };
    return newChatObj;
  }
  return null;
};

// Function to sort items based on modified_date
export const groupChatHistoryRecordsByDate = (chatHistoryRecords) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const previous7Days = new Date(today);
  previous7Days.setDate(previous7Days.getDate() - 7);
  const previous30Days = new Date(today);
  previous30Days.setDate(previous30Days.getDate() - 30);

  const groupedData = {
    Today: [],
    Yesterday: [],
    'Previous 7 Days': [],
    'Previous 30 Days': [],
  };
  if (chatHistoryRecords && chatHistoryRecords.length > 0) {
    chatHistoryRecords.forEach((record) => {
      const recordDate = new Date(record?.modified_date || record?.created_date);
      if (recordDate >= today) {
        groupedData['Today'].push(record);
      } else if (recordDate >= yesterday) {
        groupedData['Yesterday'].push(record);
      } else if (recordDate >= previous7Days) {
        groupedData['Previous 7 Days'].push(record);
      } else if (recordDate >= previous30Days) {
        groupedData['Previous 30 Days'].push(record);
      } else {
        const monthYear = recordDate.toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        });
        if (!groupedData[monthYear]) {
          groupedData[monthYear] = [];
        }
        groupedData[monthYear].push(record);
      }
    });
    return groupedData;
  }
};