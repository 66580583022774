import { addNewChatToChatHistory, groupChatHistoryRecordsByDate } from 'utils/chatHistory/chatHistory';
import axios from 'axios';
import CONFIG from 'config';
import { getRandomString } from 'utils/string-mapper/string-mapper';
import { actions as chatActions } from 'redux/api/chat/chat';

export const name = 'chatHistory';
export const CHAT_HISTORY_PENDING = 'CHAT_HISTORY_PENDING';
export const CHAT_HISTORY_FAILURE = 'CHAT_HISTORY_FAILURE';
export const FETCH_CHAT_HISTORY = 'FETCH_CHAT_HISTORY';
export const ACTIVE_CHAT_HISTORY_ITEM = 'ACTIVE_CHAT_HISTORY_ITEM';
export const SET_CHAT_HISTORY_MESSAGE = 'SET_CHAT_HISTORY_MESSAGE';
export const ADD_NEW_CHAT_TO_CHAT_HISTORY = 'ADD_NEW_CHAT_TO_CHAT_HISTORY';
export const UPDATE_CHAT_HISTORY_TITLE = 'UPDATE_CHAT_HISTORY_TITLE';
export const DELETE_CHAT_HISTORY = 'DELETE_CHAT_HISTORY';

const initialState = {
  chatHistoryRecords: [],
  groupedChatHistory: {},
  activeChatRecordIndex: 0,
  activeChatRecordGroup: 'Today',
  chatHistoryId: null,
  chatHistoryLoading: false,
  chatHistoryByIdLoading: false,
  chatMessagesLoading: false,
};

const fetchChatHistory = (chatHistoryID) => async (dispatch) => {
  dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryLoading: true } });
  try {
    const response = await axios.get(CONFIG.API_URL.GENAI_CHAT_HISTORY);
    if (!chatHistoryID) {
      const newChatObj = addNewChatToChatHistory(response);
      if (newChatObj)
        response.unshift(newChatObj);
    }
    dispatch({
      type: FETCH_CHAT_HISTORY, payload: {
        chatHistoryRecords: response,
        groupedChatHistory: groupChatHistoryRecordsByDate(response)
      }
    });
  } catch (error) {
    console.error('KNCHAT callChatHistory: API call failed', error);
    dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
    return null;
  } finally {
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryLoading: false } });
  }
};

const fetchChatHistoryById = () => async (dispatch, getState) => {
  dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryByIdLoading: true } });
  try {
    const chatHistoryID = getState().chat.chatHistoryId;
    // Check if chatHistoryID is not exist then call API to get
    // latest chat history and update chatHistory state.
    if (!chatHistoryID) {
      try {
        const response = await axios.get(CONFIG.API_URL.GENAI_CHAT_HISTORY);
        const newChatId = response[0]?.chat_history_id;
        dispatch(chatActions.setChatHistoryId(newChatId));
        dispatch({
          type: FETCH_CHAT_HISTORY, payload: {
            chatHistoryRecords: response,
          }
        });
        return null;
      } catch (error) {
        console.error('KNCHAT callChatHistory: Fetch API call failed', error);
        dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
        return null;
      }
    }
    const _response = await axios.get(`${CONFIG.API_URL.GENAI_CHAT_HISTORY}${chatHistoryID}`);
    const { activeChatRecordIndex: index, activeChatRecordGroup: group, groupedChatHistory } = getState().chatHistory;
    const chatData = { ...groupedChatHistory };
    if (index !== -1) {
      const currentRecord = chatData[group][index];
      if ((_response.title !== null && currentRecord?.title !== _response.title) || currentRecord?.chat_history_id === null) {
        chatData[group][index] = {
          ...currentRecord,
          title: _response.title,
          chat_history_id: chatHistoryID,
        };
    
        dispatch({
          type: FETCH_CHAT_HISTORY,
          payload: {
            groupedChatHistory: chatData
          }
        });
      }
    }
  } catch (error) {
    dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
    return null;
  } finally {
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatHistoryByIdLoading: false } });
  }
};

const addNewChatObjectToChatHistory = (chatData) => async (dispatch) => {
  try {
    const newObj = addNewChatToChatHistory(chatData);
    if (newObj) {
      await dispatch({
        type: ADD_NEW_CHAT_TO_CHAT_HISTORY, payload: newObj
      });
    }
  } catch (error) {
    console.error('KNCHAT New Chat: Already Exist!');
    return null;
  }
};

const getChatHistoryMessagesByID = (chatHistoryID) => async (dispatch, getState) => {
  dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatMessagesLoading: true } });
  if (!chatHistoryID) {
    return null;
  }
  try {
    const requestId = getState().chat.requestId || getRandomString(20);
    const requestData = {
      requestId: requestId,
      consumerId: 'KN',
    };

    const response = await axios.post(`${CONFIG.API_URL.GENAI_CHAT_HISTORY}${chatHistoryID}`, requestData);
    dispatch(chatActions.startNewChat());
    const processedMessages = [];
    for (let index = 0; index < response.length; index++) {
      const processedMessage = await chatActions.processMessage(response[index], requestId);
      if (processedMessage !== null) {
        processedMessage.id = index;
        chatActions.getSourcesFromSearch(processedMessage, requestId);
        processedMessages.push(processedMessage);
      }
    }
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatMessagesLoading: false } });
    dispatch(chatActions.updateChatMessages(processedMessages));
  } catch (error) {
    console.error('KNCHAT callChatHistory: API call failed', error);
    dispatch({ type: CHAT_HISTORY_FAILURE, payload: error });
    return null;
  } finally {
    dispatch({ type: CHAT_HISTORY_PENDING, payload: { chatMessagesLoading: false } });
  }
};

const setSelectedChatHistoryItem = (index, dateGroup) => async (dispatch) => {
  dispatch({
    type: ACTIVE_CHAT_HISTORY_ITEM,
    payload: { index, dateGroup },
  });
};

const updateChatHistoryTitle = (item, newTitle) => async (dispatch) => {
  try {
    const response = await axios.get(`${CONFIG.API_URL.GENAI_CUSTOMER_DATA}/KN`);
    const customerId = response.customer_id;
    const requestBody = {
      title: newTitle,
      customer_id: customerId,   
      chat_history_id: item.chat_history_id,
      request_id: getRandomString(20),   
    };
    await axios.put(`${CONFIG.API_URL.GENAI_CHAT_HISTORY_TITLE}${item.chat_history_id}`, requestBody);
    dispatch({
      type: UPDATE_CHAT_HISTORY_TITLE,
      payload: { item, newTitle },
    });
  } catch (error) {
    console.error('KNCHAT editChatHistoryTitle: API call failed', error);
  }
};

const deleteChatHistory = (item) => async (dispatch) => {
  try {
    const response = await axios.get(`${CONFIG.API_URL.GENAI_CUSTOMER_DATA}/KN`);
    const requestBody = {
      request_id: getRandomString(20),
      customer_id: response.customer_id,
    };
    await axios.put(`${CONFIG.API_URL.GENAI_CHAT_HISTORY}delete/${item.chat_history_id}`, requestBody);
    dispatch({
      type: DELETE_CHAT_HISTORY,
      payload: item,
    });
  } catch (error) {
    console.error('KNCHAT deleteChat: API call failed', error);
  }
};

export const actions = {
  fetchChatHistory,
  setSelectedChatHistoryItem,
  addNewChatObjectToChatHistory,
  getChatHistoryMessagesByID,
  fetchChatHistoryById,
  updateChatHistoryTitle,
  deleteChatHistory,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_HISTORY_PENDING:
      return {
        ...state,
        error: false,
        errorMessage: '',
        chatHistoryLoading: action.payload?.chatHistoryLoading,
        chatHistoryByIdLoading: action.payload?.chatHistoryByIdLoading,
        chatMessagesLoading: action.payload?.chatMessagesLoading,
      };
    case FETCH_CHAT_HISTORY:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        chatHistoryRecords: action.payload?.chatHistoryRecords,
        groupedChatHistory: action.payload?.groupedChatHistory,
      };
    case CHAT_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload?.message
      };
    case ADD_NEW_CHAT_TO_CHAT_HISTORY:
      const updatedGroupedChatHistory = { ...state.groupedChatHistory };
      updatedGroupedChatHistory['Today'] = [{ ...action.payload }, ...updatedGroupedChatHistory['Today']];
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        groupedChatHistory: updatedGroupedChatHistory
      };
    case ACTIVE_CHAT_HISTORY_ITEM:
      const { index = 0, dateGroup = 'Today' } = action.payload || {};
      return {
        ...state,
        activeChatRecordIndex: index,
        activeChatRecordGroup: dateGroup
      };
    case UPDATE_CHAT_HISTORY_TITLE:
      return {
        ...state,
        chatHistoryRecords: state.chatHistoryRecords.map((item) =>
          item === action.payload.item
            ? { ...item, title: action.payload.newTitle }
            : item
        ),
      };
    case DELETE_CHAT_HISTORY:
      return {
        ...state,
        chatHistoryRecords: state.chatHistoryRecords.filter(item => item !== action.payload),
      };
    default:
      return state;
  }
};
