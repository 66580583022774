import axios from 'axios';
import CONFIG from 'config';

export function downloadFile(kpCmsId) {
  let downloadUrl = '';
  downloadUrl = CONFIG.API_URL.KNOWLEDGE_DOWNLOAD_FILE(kpCmsId);

  window.open(downloadUrl, '_blank');
}

export async function downloadPreparedFile(downloadUrl, fileName) {
  let downloadResponse;
  try {
    downloadResponse = await axios.get(downloadUrl, { responseType: 'blob', });
  } catch (err) {
    console.log('Error downloading:', err);
    throw err;
  }
  const fileBlob = new Blob([downloadResponse], { type: 'mime' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(fileBlob, `${fileName}`);
  } else {
    const blobDownloadResponse = window.URL.createObjectURL(fileBlob);
    const tempLink = document.createElement('a');
    tempLink.href = blobDownloadResponse;
    tempLink.setAttribute('download', `${fileName}`);
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.remove();
  }
}

export const downloadHostMapper = (originalHost, binaryMappings) => {
  const binaryMapping = JSON.parse(binaryMappings)
    .find((m) => originalHost.includes(m.from));
  return originalHost.replace(binaryMapping.from, binaryMapping.to);
};
