/* eslint-disable no-console */
import Hashes from 'jshashes';
import { getUserEmail, isUserAuthenticated } from 'utils/auth/auth';
import { isNullOrUndefined } from 'util';
import { datadogRum } from '@datadog/browser-rum';
import { selectors as searchSelectors } from 'redux/api/search/search';
import { selectors as bcgInternalSelectors } from 'redux/api/bcgInternal/bcgInternal';
import { selectors as labSelectors } from 'redux/api/lab/lab';
import { selectors as expertSelectors } from 'redux/api/expertSearch/expertSearch';
import { selectors as peopleResultSelectors } from 'redux/api/peopleResults/peopleResults';
import { selectors as caseResultSelectors } from 'redux/api/casesv2Search/casesv2Search';
import { selectors as knowledgeSelectors } from 'redux/api/knowledge/knowledge';
import { merge } from 'lodash';
import store from 'redux/store';
import CONFIG from 'config';
import {
  EMPTY_QUERY,
  NO_VAR,
  PA_RECOMMENDED,
  PA_NOT_RECOMMENDED,
  CARD_EXPANDED,
  CARD_COLLAPSED,
  RELEVANT,
  NOT_RELEVANT
} from './analytics_constants';
import { getParameterValueFromUrl } from 'utils/string-mapper/string-mapper';

let appDefaults = null;

export default {
  getAppDefaults: () => appDefaults,
  async initialize(pageName, category, trackPageLoad) {
    let email = 'unknown';
    let lowerCaseEmail = 'unknown';
    let staffEmailID = 'unknown';
    if (await isUserAuthenticated()) {
      email = await getUserEmail();
      if (!email) {
        console.error('page_analytics initialize email is null');
      } else {
        lowerCaseEmail = email.toLowerCase();
        staffEmailID = new Hashes.SHA256().hex(lowerCaseEmail);
      }
    }
    const brand = CONFIG.BRANDS.filter((b) => b.URL === window.location.host)[0];

    const data = {
      page: {
        pageInfo: { pageName: pageName || '' },
        headerInfo: {
          staffEmailID,
          toolName: brand.TOOLNAME_IN_ADOBE
        },
        category: { primaryCategory: category }
      }
    };

    appDefaults = { ...data };

    if (trackPageLoad) {
      setTimeout(() => {
        // This 'data' object is resetting 'window.digitialData' completely.
        // Add timeOut() to avoid race condition, because there might be some in-processing analytic events that still rely upon old 'window.digitalData.search' value.
        // If not doing that, then some important attributes would become missing, when Omnibug displays the processed events.
        // So reset 'window.digitalData' with data in next cycle.
        window.digitalData = data;
        this.track('virtual_page_load');
      }, 1000);
    }
  },

  // deprecated, don't use this event, use trackEvent instead
  trackIt: async (eventName, analyticObject) => {
    // TODO maybe add a clear option to rerrun initialize func above so digitalData is cleaned
    try {
      window.digitalData = Object.assign(window.digitalData, analyticObject);
      if (window.digitalData && window.digitalData.search && window.digitalData.search.term) {
        window.digitalData.search.term = decodeURIComponent(window.digitalData.search.term);
      }
      console.log('trackIt digitaldata', window.digitalData);
      _satellite.track(eventName);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  },

  // deprecated, don't use this event, use trackEvent instead
  track(eventName, options = {
    integrations: {
      Adobe: true,
      DataDog: false
    },
    dataDogEvent: null
  }) {
    try {
      if (options.integrations.Adobe) {
        if (window.digitalData && window.digitalData.search && window.digitalData.search.term) {
          window.digitalData.search.term = decodeURIComponent(window.digitalData.search.term);
        }
        console.log('track digitaldata', window.digitalData, eventName);
        _satellite.track(eventName);
      }
    } catch (err) {
      console.error(err);
    }

    try {
      if (options.integrations.DataDog) {
        datadogRum.addAction(eventName, options.dataDogEvent || window.digitalData);
      }
    } catch (err) {
      console.error(err);
    }
  },

  trackEvent: (eventName, dataObj) => {
    if (appDefaults) {
      try {
        window.digitalData = merge({}, appDefaults, dataObj);
        if (window.digitalData && window.digitalData.search && window.digitalData.search.term) {
          window.digitalData.search.term = decodeURIComponent(window.digitalData.search.term);
        }
        console.log('trackEvent digitaldata', window.digitalData, eventName);
        _satellite.track(eventName);
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error('ANALYTICS cannot trigger because appDefaults is empty. Run `initialize` to set.');
    }
  },

  getFilterTerms(scope, currQuery) {
    const searchScope = scope || searchSelectors.getSearchScope(store.getState());

    let filterTerms = [];
    let lastSearchQuery;
    if (searchScope === CONFIG.SEARCH.SCOPES.KNOWLEDGE) {
      filterTerms = Object.values(CONFIG.KNOWLEDGE_FILTERS.FILTER_BAR).map((f) => f.QUERY_PARAM);
      // MISSING QI FILTER APPLIED vvv
      // eslint-disable-next-line prefer-destructuring
      lastSearchQuery = knowledgeSelectors.getLastSearch(store.getState()).query;
    } else if (searchScope === CONFIG.SEARCH.SCOPES.PEOPLE) {
      filterTerms = Object.values(CONFIG.PEOPLE_FILTERS).map((f) => f.QUERY_PARAM);
      // eslint-disable-next-line prefer-destructuring
      lastSearchQuery = peopleResultSelectors.getLastSearch(store.getState()).query;
    } else if (searchScope === CONFIG.SEARCH.SCOPES.CASE) {
      filterTerms = Object.values(CONFIG.CASE_FILTERS.FILTER_BAR).map((f) => f.QUERY_PARAM);
      Object.values(CONFIG.CASE_QUERY_PARAMS).forEach(value => {
        if (filterTerms.indexOf(value) === -1) {
          filterTerms.push(value);
        }
      });
      // eslint-disable-next-line prefer-destructuring
      lastSearchQuery = caseResultSelectors.getLastSearch(store.getState()).query;
    } else if (searchScope === CONFIG.SEARCH.SCOPES.LAB) {
      filterTerms = Object.values(CONFIG.LAB_FILTERS.FILTER_BAR).map((f) => f.QUERY_PARAM);
      // eslint-disable-next-line prefer-destructuring
      lastSearchQuery = labSelectors.getLastSearch(store.getState()).query;
    } else if (searchScope === CONFIG.SEARCH.SCOPES.EXPERTS) {
      filterTerms = Object.values(CONFIG.EXPERT_FILTERS.FILTER_BAR).map((f) => f.QUERY_PARAM);
      Object.values(CONFIG.EXPERT_QUERY_PARAMS).forEach(value => {
        if (filterTerms.indexOf(value) === -1) {
          filterTerms.push(value);
        }
      });
      // eslint-disable-next-line prefer-destructuring
      lastSearchQuery = expertSelectors.getLastSearch(store.getState()).query;
    } else if (searchScope === CONFIG.SEARCH.SCOPES.BCG_INTERNAL) {
      filterTerms = Object.values(CONFIG.BCG_INTERNAL_FILTERS.FILTER_BAR).map((f) => f.QUERY_PARAM);
      // MISSING QI FILTER APPLIED vvv
      // eslint-disable-next-line prefer-destructuring
      lastSearchQuery = bcgInternalSelectors.getLastSearch(store.getState()).query;
    }

    const query = currQuery ? currQuery : lastSearchQuery;
    const terms = filterTerms
      .filter((term) => query
        && query[term] !== undefined
        && term !== CONFIG.QUERY_PARAMS.ENABLE_AUTO_CORRECT
        && term !== CONFIG.QUERY_PARAMS.SORTING_ORDER
        && term !== CONFIG.QUERY_PARAMS.QUERY)
      .map((term) => (`${term}=`).concat(query[term]))
      .join('|');

    return terms || NO_VAR;
  },

  getOrderTerms(scope, currQuery) {
    const searchScope = scope || searchSelectors.getSearchScope(store.getState());

    const { router: { location: { query: routerLocationQuery } } } = store.getState();
    const recommendedBy = routerLocationQuery.recommendedBy ? ('recommendedBy=').concat(routerLocationQuery.recommendedBy) : NO_VAR;

    let sortBy;
    let lastSearchQuery;
    switch (searchScope) {
      case CONFIG.SEARCH.SCOPES.KNOWLEDGE: {
        // eslint-disable-next-line prefer-destructuring
        lastSearchQuery = knowledgeSelectors.getLastSearch(store.getState()).query;
        if (currQuery) {
          sortBy = currQuery.sortingOrder || null;
        } else {
          sortBy = lastSearchQuery?.sortingOrder || null;
        }
        break;
      }
      case CONFIG.SEARCH.SCOPES.PEOPLE: {
        // eslint-disable-next-line prefer-destructuring
        lastSearchQuery = peopleResultSelectors.getLastSearch(store.getState()).query;
        if (currQuery) {
          sortBy = currQuery.sortingOrder || null;
        } else {
          const lastSearch = peopleResultSelectors.getLastSearch(store.getState());
          if (lastSearch && lastSearch.sort) {
            sortBy = `${lastSearch.sort.field} ${lastSearch.sort.direction}`;
          } else {
            sortBy = null;
          }
        }

        break;
      }
      case CONFIG.SEARCH.SCOPES.CASE: {
        // eslint-disable-next-line prefer-destructuring
        lastSearchQuery = caseResultSelectors.getLastSearch(store.getState()).query;
        if (currQuery) {
          sortBy = currQuery.sortingOrder || null;
        } else {
          sortBy = lastSearchQuery?.sortingOrder || null;
        }
        break;
      }
      case CONFIG.SEARCH.SCOPES.EXPERTS: {
        // eslint-disable-next-line prefer-destructuring
        lastSearchQuery = expertSelectors.getLastSearch(store.getState()).query;
        if (currQuery) {
          sortBy = currQuery.sortingOrder || null;
        } else {
          sortBy = lastSearchQuery?.sortingOrder || null;
        }
        break;
      }
      case CONFIG.SEARCH.SCOPES.LAB: {
        // eslint-disable-next-line prefer-destructuring
        lastSearchQuery = labSelectors.getLastSearch(store.getState()).query;
        if (currQuery) {
          sortBy = currQuery.sortingOrder || null;
        } else {
          sortBy = lastSearchQuery?.sortingOrder || null;
        }
        break;
      }
      case CONFIG.SEARCH.SCOPES.BCG_INTERNAL: {
        // eslint-disable-next-line prefer-destructuring
        lastSearchQuery = bcgInternalSelectors.getLastSearch(store.getState()).query;
        if (currQuery) {
          sortBy = currQuery.sortingOrder || null;
        } else {
          sortBy = lastSearchQuery?.sortingOrder || null;
        }
        break;
      }
      default:
        sortBy = null;
        break;
    }

    return [sortBy,
      decodeURIComponent(recommendedBy)]
      .filter((x) => x !== NO_VAR).join('|') || NO_VAR;
  },

  getSinequaProfile(scope) {
    const searchScope = scope || searchSelectors.getSearchScope(store.getState());

    let profile;
    if (!(searchScope === CONFIG.SEARCH.SCOPES.PEOPLE)) {
      profile = searchSelectors.getSearchProfiles(store.getState());
    } else {
      return NO_VAR;
    }
    return profile;
  },

  getSearchScope() {
    return searchSelectors.getSearchScope(store.getState());
  },

  setDefaultQueryProps(scope) {
    const searchScope = scope || searchSelectors.getSearchScope(store.getState());
    const { router: { location: { query } } } = store.getState();
    const { isSearchTermCorrected, correctedSearchTerm } = { isSearchTermCorrected: false, correctedSearchTerm: '' }; // wasn't working before, bug being made

    let term;
    if (isSearchTermCorrected) {
      term = correctedSearchTerm;
    } else if (query.query !== undefined) {
      if (query.emptyQuery) {
        term = EMPTY_QUERY;
      } else {
        term = query.query;
      }
    } else {
      term = NO_VAR;
    }

    const adobe = {
      search: {
        term,
        type: searchScope
      },
      preFilterTerm: this.getFilterTerms(searchScope),
      preOrderTerm: this.getOrderTerms(searchScope),
      sinequaProfile: this.getSinequaProfile(searchScope)
    };
    window.digitalData = Object.assign(window.digitalData || {}, adobe);
  },

  setDefaultFileProps(doc, isExpanded, displayOption, chapter, lesson, parentDoc = {}) {
    const isAssociatedBundle = Object.keys(parentDoc).length !== 0;
    const isKnowledge = doc.resultType === 'KPA' || doc.resultType === 'KBU';
    const displayMode = displayOption;

    this.setDefaultQueryProps();

    let labLessonID;
    let labLessonName;
    let resultCardType;
    switch (doc.resultType) {
      case 'LT':
        labLessonID = doc?.labLessonNumber || NO_VAR;
        // eslint-disable-next-line prefer-destructuring
        labLessonName = doc?.labLessonName || NO_VAR;
        resultCardType = doc?.docType || NO_VAR;
        break;
      case 'KTC': //knowledge topic collection
        labLessonID = lesson?.lessonId || NO_VAR;
        labLessonName = lesson?.lessonName || NO_VAR;
        resultCardType = CONFIG.KNOWLEDGE.TOPIC_COLLECTION;
        break;
      default:
        labLessonID = lesson?.lessonId || NO_VAR;
        labLessonName = lesson?.lessonName || NO_VAR;
        resultCardType = doc?.docType || NO_VAR;
        break;
    }

    const adobe = {
      materialID: isKnowledge ? NO_VAR : doc.materialId || NO_VAR,
      attachmentID: isKnowledge ? NO_VAR : doc.attachmentId || NO_VAR,
      caseID: doc.projectId || NO_VAR,
      materialURL: doc.materialURL || doc.materialUrl || NO_VAR,
      globalRelevance: isAssociatedBundle ? parentDoc.globalRelevance : (doc.relevancyScore || doc.globalRelevance) || NO_VAR,
      searchResultRanking: typeof doc?.docRank === 'number' ? (isAssociatedBundle ? parentDoc.docRank + 1 : doc.docRank + 1) : NO_VAR, // we should always receive a 0 based index value
      materialPAStatus: doc.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED,
      // eslint-disable-next-line no-nested-ternary
      cardExpansionStatus: displayMode === CONFIG.DISPLAY_MODE.GRID ? NO_VAR : (isExpanded ? CARD_EXPANDED : CARD_COLLAPSED),
      resultType: isAssociatedBundle ? 'KBU' : doc?.resultType || NO_VAR,
      labCourseID: doc?.labCourseId || NO_VAR,
      labCourseName: doc?.labCourseName || NO_VAR,
      labChapterID: chapter?.chapterId || NO_VAR,
      labChapterName: chapter?.chapterName || NO_VAR,
      labLessonID,
      labLessonName,
      resultCardType
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setDefaultMaterialProps(doc, displayOption = null) {
    let displayMode = displayOption;
    if (!displayOption) {
      displayMode = 'list';
    }
    const isKnowledge = doc.resultType === 'KPA' || doc.resultType === 'KBU';
    this.setDefaultQueryProps();
    const adobe = {
      // material page will always have 'not available' as a value for globalRelevance, searchResultRanking, cardExpansionStatus
      displayMode,
      materialID: isKnowledge ? NO_VAR : doc.materialId,
      attachmentID: isKnowledge ? NO_VAR : doc.attachmentId,
      materialURL: doc.materialURL || doc.materialUrl || NO_VAR,
      globalRelevance: doc.relevancyScore || NO_VAR,
      searchResultRanking: doc.docRank + 1,
      materialPAStatus: doc.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED,
      cardExpansionStatus: NO_VAR,
      resultType: doc.resultType,
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setDefaultOtherAttachmentFilesProps(doc, attachments, displayMode, allItemsSelected = false) {
    this.setDefaultQueryProps();
    const attachmentIds = this.getMaterialParams(attachments, 'attachmentId');
    const materialIds = this.getMaterialParams(attachments, 'materialId');
    const isCaseVignette = doc.caseVignettesStatus === 'True';
    const adobe = {
      displayMode,
      materialID: isCaseVignette ? materialIds : doc.materialId,
      attachmentID: isCaseVignette ? NO_VAR : doc.attachmentId,
      globalRelevance: doc.relevancyScore ? doc.relevancyScore : NO_VAR,
      searchResultRanking: doc.docRank ? doc.docRank + 1 : NO_VAR, // we should always receive a 0 based index value
      materialPAStatus: doc.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED,
      otherAttachmentID: attachmentIds,
      itemsSelectionStatus: allItemsSelected === true ? 'true' : 'false',
      resultType: doc.resultType ? doc.resultType : 'MA'
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setDefaultPinnedBulkProps(attachments, displayMode, allItemsSelected = false) {
    this.setDefaultQueryProps();
    const resultType = this.getMaterialParams(attachments, 'resultType', NO_VAR);
    const adobe = {
      displayMode,
      resultType,
      itemsSelectionStatus: allItemsSelected === true ? 'true' : 'false'
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setDefaultAttachmentFileProps(doc, isExpanded, displayOption, chapter, lesson, parentDoc = {}) {
    this.setDefaultFileProps(doc, isExpanded, displayOption, chapter, lesson, parentDoc);
    const isAssociatedBundle = Object.keys(parentDoc).length !== 0;
    const adobe = {
      attachmentID: doc.parentAttachmentId,
      otherAttachmentID: doc.attachmentId,
      resultType: isAssociatedBundle ? 'KBU' : doc.resultType || NO_VAR
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setDefaultMaterialAttachmentFileProps(doc) {
    this.setDefaultMaterialProps(doc, CONFIG.DISPLAY_MODE.MATERIAL);

    const adobe = {
      attachmentID: doc.parentAttachmentId,
      otherAttachmentID: doc.attachmentId
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setDefaultOverlayFileProps(doc, displayOption = null) {
    let displayMode = displayOption;
    if (!displayOption) {
      displayMode = 'list';
    }
    this.setDefaultQueryProps();
    const adobe = {
      displayMode,
      materialID: doc.materialId,
      attachmentID: doc.attachmentId,
      caseID: doc.projectId || NO_VAR,
      globalRelevance: NO_VAR,
      searchResultRanking: NO_VAR,
      materialPAStatus: doc.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED,
      numberofSlides: doc.slides ? doc.slides.length : NO_VAR,
      resultType: doc.resultType
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  getMaterialParams(attachments = [], paramName, emptyVal = '') {
    if (!attachments?.length) {
      return emptyVal;
    }

    // pinned docs
    if (attachments.every((a) => a.doc)) {
      return attachments.map((a) => {
        if (typeof a.doc[paramName] !== 'undefined' && a.doc[paramName] !== null && a.doc[paramName] !== '') {
          return a.doc[paramName];
        }
        return emptyVal;
      }).join('|');
    }
    // one other attachment
    if (attachments.length === 1) {
      return (typeof attachments[0][paramName] !== 'undefined' && attachments[0][paramName] !== null && attachments[0][paramName] !== '') ? `${attachments[0][paramName]}` : emptyVal;
    }

    return attachments.map((a) => {
      if (typeof a[paramName] !== 'undefined' && a[paramName] !== null && a[paramName] !== '') {
        return a[paramName];
      }
      return emptyVal;
    }).join('|');
  },

  setLABTalkProps(doc, isExpanded, displayOption) {
    let displayMode = displayOption;
    if (!displayOption) {
      displayMode = 'list';
    }
    this.setDefaultQueryProps();
    const adobe = {
      displayMode,
      globalRelevance: doc.relevancyScore,
      searchResultRanking: !isNullOrUndefined(doc.docRank) ? doc.docRank + 1 : NO_VAR,
      // eslint-disable-next-line no-nested-ternary
      cardExpansionStatus: displayMode === CONFIG.DISPLAY_MODE.GRID ? NO_VAR : (isExpanded ? CARD_EXPANDED : CARD_COLLAPSED),
      labLessonID: doc.labLessonNumber,
      labLessonName: doc.labLessonName,
      labCourseID: doc.labCourseId,
      labCourseName: doc.labCourseName,
      resultType: doc.resultType
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setLABCourseProps(doc, isExpanded, displayOption) {
    let displayMode = displayOption;
    if (!displayOption) {
      displayMode = 'list';
    }
    this.setDefaultQueryProps();
    const adobe = {
      displayMode,
      globalRelevance: doc.relevancyScore,
      searchResultRanking: !isNullOrUndefined(doc.docRank) ? doc.docRank + 1 : NO_VAR,
      // eslint-disable-next-line no-nested-ternary
      cardExpansionStatus: displayMode === CONFIG.DISPLAY_MODE.GRID ? NO_VAR : (isExpanded ? CARD_EXPANDED : CARD_COLLAPSED),
      labCourseID: doc.labCourseId,
      labCourseName: doc.labCourseName,
      resultType: doc.resultType
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setLABChapterProps(doc, chapter, isExpanded, displayOption) {
    let displayMode = displayOption;
    if (!displayOption) {
      displayMode = 'list';
    }
    this.setDefaultQueryProps();
    const adobe = {
      displayMode,
      globalRelevance: doc.relevancyScore,
      searchResultRanking: !isNullOrUndefined(doc.docRank) ? doc.docRank + 1 : NO_VAR,
      // eslint-disable-next-line no-nested-ternary
      cardExpansionStatus: displayMode === CONFIG.DISPLAY_MODE.GRID ? NO_VAR : (isExpanded ? CARD_EXPANDED : CARD_COLLAPSED),
      labCourseID: doc.labCourseId,
      labCourseName: doc.labCourseName,
      labChapterID: chapter.chapterId,
      labChapterName: chapter.chapterName,
      resultType: doc.resultType
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setLABLessonProps(doc, chapter, lesson, isExpanded, displayOption) {
    let displayMode = displayOption;
    if (!displayOption) {
      displayMode = 'list';
    }
    this.setDefaultQueryProps();
    const adobe = {
      displayMode,
      globalRelevance: doc.relevancyScore,
      searchResultRanking: !isNullOrUndefined(doc.docRank) ? doc.docRank + 1 : NO_VAR,
      // eslint-disable-next-line no-nested-ternary
      cardExpansionStatus: displayMode === CONFIG.DISPLAY_MODE.GRID ? NO_VAR : (isExpanded ? CARD_EXPANDED : CARD_COLLAPSED),
      labCourseID: doc.labCourseId,
      labCourseName: doc.labCourseName,
      labLessonID: lesson.lessonId,
      labLessonName: lesson.lessonName,
      labChapterID: chapter.chapterId,
      labChapterName: chapter.chapterName,
      resultType: doc.resultType
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  setNoVarForSearchType() {
    const adobe = { search: { type: NO_VAR }, };

    window.digitalData = Object.assign(window.digitalData, adobe);
  },

  getAnalyticsValFromUrlParameter(parameterName) {
    return getParameterValueFromUrl(parameterName) || NO_VAR;
  }
};