export function copyToClipboard(stringToCopy, isMobileSafari = false) {
  const textArea = document.createElement('textarea');
  textArea.value = stringToCopy;
  document.body.appendChild(textArea);
  textArea.style.position = 'fixed';
  textArea.style.bottom = 0;
  textArea.style.left = 0;
  let range;
  let selection;
  if (isMobileSafari) {
    textArea.contentEditable = true;
    textArea.readOnly = true;
    range = document.createRange();
    range.selectNodeContents(textArea);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

export const copyFormattedTextToClipboard = (text) => {
  const div = document.createElement('div');
  div.innerHTML = text;
  document.body.appendChild(div);

  const range = document.createRange();
  range.selectNodeContents(div);

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);

  document.execCommand('copy');
  selection.removeAllRanges();
  document.body.removeChild(div);
};

export const copyToClip = async (text) => navigator.clipboard.writeText(text);