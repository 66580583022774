import { selectors as searchSelectors } from 'redux/api/search/search';
import store from 'redux/store';
import CONFIG from 'config';
import PAGE_ANALYTICS from './page_analytics';
import {
  NO_VAR,
  CARD_EXPANDED,
  CARD_COLLAPSED,
  PA_RECOMMENDED,
  PA_NOT_RECOMMENDED,
  IS_BEST_BET,
  IS_NOT_BEST_BET
} from './analytics_constants';
import tabs from 'containers/SERP/tabs';

export default {
  async downloadFile(doc, isExpanded) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded);
    const adobe = {
      KCpage: { id: doc.kpCmsId || NO_VAR },
      caseID: doc?.projectId || doc?.parentProjectId || NO_VAR,
      collection: { ID: doc.kbCmsId || NO_VAR },
      search: { ...window.digitalData.search, bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET },
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('download_interactions');
    delete window.digitalData.search.bestbetflag;
  },

  async emailFile(doc, isExpanded) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded);
    const adobe = {
      KCpage: { id: doc.kpCmsId || NO_VAR },
      caseID: doc?.projectId || doc?.parentProjectId || NO_VAR,
      search: { ...window.digitalData.search, bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET },
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('email_interactions');
    delete window.digitalData.search.bestbetflag;
  },

  async previewFile(doc, isExpanded) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded);
    const adobe = {
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
      search: { ...window.digitalData.search, bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET },
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('preview_interactions', {
      integrations: {
        Adobe: true,
        DataDog: true
      }
    });
    delete window.digitalData.search.bestbetflag;
  },

  async downloadOtherFile(doc, isExpanded) {
    PAGE_ANALYTICS.setDefaultAttachmentFileProps(doc, isExpanded);
    PAGE_ANALYTICS.track('download_attachment_otherDocument');
  },

  async emailOtherFile(doc, isExpanded, displayMode) {
    PAGE_ANALYTICS.setDefaultAttachmentFileProps(doc, isExpanded, displayMode);
    PAGE_ANALYTICS.track('email_attachment_otherDocument');
  },

  async previewOtherFile(doc, isExpanded) {
    PAGE_ANALYTICS.setDefaultAttachmentFileProps(doc, isExpanded);
    PAGE_ANALYTICS.track('preview_attachment_otherDocument', {
      integrations: {
        Adobe: true,
        DataDog: true
      }
    });
  },

  async expandCard(doc) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, true);
    const adobe = {
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
      search: { ...window.digitalData.search, bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET }
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('expand_result_card');
    delete window.digitalData.search.bestbetflag;
  },

  async viewAuthor(doc, authorStaffID, displayMode) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, true, displayMode);

    const adobe = { authorStaffID };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('view_profile_result_expanded_view');
  },

  async slackAuthor(doc, authorStaffID, displayMode) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, true, displayMode);

    const adobe = {
      authorStaffID,
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('author_slack_interaction');
  },

  async emailAuthor(doc, authorStaffID, displayMode) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, true, displayMode);

    const adobe = {
      authorStaffID,
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('author_email_interaction');
  },

  async collapseCard(doc) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, false);
    const adobe = {
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
      search: {
        bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET,
      }
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('collapse_result_card');
    delete window.digitalData.search.bestbetflag;
  },

  loadMore(displayMode, targetedPageNumber) {
    PAGE_ANALYTICS.setDefaultQueryProps();

    const adobe = {
      displayMode: displayMode,
      SERPview: displayMode + ' view',
      targetedPageNumber: targetedPageNumber
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('show_more_button');
  },

  async visitUrl(doc, isExpanded) {
    PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded);
    const adobe = {
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
      search: { ...window.digitalData.search, bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET }
    };
    window.digitalData = Object.assign(window.digitalData, adobe);

    PAGE_ANALYTICS.track('visit_url_interactions');
    delete window.digitalData.search.bestbetflag;
  },

  async pinVisitUrlOther(doc, isExpanded) {
    PAGE_ANALYTICS.setDefaultAttachmentFileProps(doc, isExpanded);
    PAGE_ANALYTICS.track('visit_url_interactions_other_attachments');
  },

  async emailBulkFiles(doc, attachments, displayMode, allItemsSelected) {
    PAGE_ANALYTICS.setDefaultOtherAttachmentFilesProps(doc, attachments, displayMode, allItemsSelected);
    const materialURL = attachments.map((a) => {
      if (a.materialURL) return a.materialURL;
      return 0;
    }).join('|');
    const adobe = {
      materialURL,
      resultType: PAGE_ANALYTICS.getMaterialParams(attachments, 'resultType', NO_VAR)
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('bulk_email_other_attachments');
  },

  async downloadBulkFiles(doc, attachments, displayMode, allItemsSelected) {
    PAGE_ANALYTICS.setDefaultOtherAttachmentFilesProps(doc, attachments, displayMode, allItemsSelected);
    const adobe = { resultType: PAGE_ANALYTICS.getMaterialParams(attachments, 'resultType', NO_VAR) };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('bulk_download_other_attachments');
  },

  async copyDetails(doc, isExpanded, flavor, chapter, lesson, copyComponent, parentDoc) {
    PAGE_ANALYTICS.setDefaultAttachmentFileProps(doc, isExpanded, null, chapter, lesson, parentDoc);
    const isPinnedDocs = flavor === 'pinned_docs';
    const isMaterialPage = flavor === 'material';
    const isAttachment = !!doc.isAttachment;
    // const isCasePage = copyComponent === CONFIG.COPY.TYPE.CASE || copyComponent === CONFIG.COPY.TYPE.VIGNETTE;
    const searchScope = searchSelectors.getSearchScope(store.getState());
    const isCasePage = searchScope === CONFIG.SEARCH.SCOPES.CASE;
    let paRecommended = doc.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED;
    paRecommended = isPinnedDocs ? NO_VAR : paRecommended;
    const attachId = (isPinnedDocs || !isAttachment || isCasePage) ? doc.attachmentId : doc.parentAttachmentId;
    // eslint-disable-next-line no-nested-ternary
    const displayMode = (isMaterialPage ? CONFIG.DISPLAY_MODE.MATERIAL : (isPinnedDocs ? CONFIG.DISPLAY_MODE.PINNED_DOCS : 'list'));
    const adobe = {
      copyTrigger: flavor,
      displayMode,
      // eslint-disable-next-line no-nested-ternary
      cardExpansionStatus: (displayMode === CONFIG.DISPLAY_MODE.GRID) || (displayMode === CONFIG.DISPLAY_MODE.MATERIAL) ? NO_VAR : (isExpanded ? CARD_EXPANDED : CARD_COLLAPSED),
      materialID: doc.materialId,
      attachmentID: attachId,
      otherAttachmentID: (isPinnedDocs || !isAttachment || isCasePage) ? NO_VAR : doc.attachmentId,
      materialPAStatus: paRecommended,
      copyComponent,
      caseID: doc?.projectId || doc?.parentProjectId || NO_VAR,
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
      search: { ...window.digitalData.search, bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET },
      SERPView: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView')
    };
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('copy_details');
    delete window.digitalData.search.bestbetflag;
  },

  taxonomyClick(doc, suggestedTopicTitle, suggestedTopicGroup, suggestedTopicUrl, topicPath, displayMode) {
    const topicId = suggestedTopicUrl.split('=').splice(-1);
    PAGE_ANALYTICS.setDefaultQueryProps();
    PAGE_ANALYTICS.setDefaultFileProps(doc, null, displayMode);
    const paRecommended = doc.paRecommended ? PA_RECOMMENDED : PA_NOT_RECOMMENDED;

    const adobe = {
      suggestedTopicTitle,
      suggestedTopicPath: topicPath,
      suggestedTopicID: topicId,
      suggestedTopicGroup,
      materialPAStatus: doc.projectId ? NO_VAR : paRecommended,
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
      search: { ...window.digitalData.search, bestbetflag: doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET }
    };

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('material_page_Taxonomy_click');
    delete window.digitalData.search.bestbetflag;
  },

  async searchTabClick(scope, pageName, valuesToOverride = null, noResultsPage) {
    PAGE_ANALYTICS.setDefaultQueryProps(scope);

    if (!window.digitalData) window.digitalData = {};

    const { page = {}, search = {} } = window.digitalData;

    const searchTabName = tabs.find(tab => scope === tab.scope)?.label;

    let adobe = {
      searchTabName: searchTabName ? searchTabName : scope,
      page: {
        ...page,
        pageInfo: { pageName }
      },
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
    };

    if (valuesToOverride) {
      adobe = {
        ...adobe,
        search: {
          ...search,
          type: valuesToOverride.search.type
        },
        preFilterTerm: valuesToOverride.preFilterTerm || window.digitalData.preFilterTerm || NO_VAR,
        preOrderTerm: valuesToOverride.preOrderTerm || window.digitalData.preOrderTerm || NO_VAR
      };
    } else if (noResultsPage) {
      const tabName = `${scope}_From_NoResultPage`;
      adobe = {
        ...adobe,
        searchTabName: tabName
      };
    }

    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('Result_Page_Search_Tab');
  },

  topicTagInfo(doc, isExpanded, displayMode, scope, tagType) {
    PAGE_ANALYTICS.setDefaultQueryProps();
    PAGE_ANALYTICS.setDefaultFileProps(doc, isExpanded, displayMode, null, null);

    const adobe = {
      displayMode: displayMode || NO_VAR,
      KCpage: { id: doc.kpCmsId || NO_VAR },
      collection: { ID: doc.kbCmsId || NO_VAR },
      materialURL: ((resultType) => {
        switch (resultType) {
          case CONFIG.RESULT_TYPES.KNOWLEDGE_PAGE:
            return CONFIG.CMS.KP_URL(doc.kpCmsId);
          case CONFIG.RESULT_TYPES.KNOWLEDGE_BUNDLE:
            return CONFIG.CMS.KB_URL(doc.kbCmsId);
          default:
            return (typeof doc.projectId !== 'undefined' && doc.projectId !== null) ? CONFIG.KN_URL.CASE_URL(doc.projectId) : NO_VAR;
        }
      })(doc.resultType),
      search: {
        ...window.digitalData.search,
        bestbetflag: scope === CONFIG.SEARCH.SCOPES.KNOWLEDGE ? (doc.isBestBet ? IS_BEST_BET : IS_NOT_BEST_BET) : NO_VAR 
      },
      SERPview: PAGE_ANALYTICS.getAnalyticsValFromUrlParameter('resultsView'),
      expertTagType: (scope === CONFIG.SEARCH.SCOPES.EXPERTS) ? (tagType || NO_VAR) : NO_VAR,
      resultHrID: doc?.hrEmployeeId || NO_VAR,
      suggestedTopicTitle: NO_VAR,
      suggestedTopicPath: NO_VAR,
      suggestedTopicID: NO_VAR,
      suggestedTopicGroup: NO_VAR
    };
    if (doc.caseNumber) {
      adobe.resultType = CONFIG.SEARCH_FLAVORS.CASE;
    }
    if (scope === CONFIG.SEARCH.SCOPES.EXPERTS) {
      const expert = doc;
      if (typeof expert?.relevancyDetails?.position === 'number') {
        adobe.searchResultRanking = expert.relevancyDetails.position + 1;
      } else if (typeof expert?.relevancyDetails?.position === 'string' && expert?.relevancyDetails?.position.trim().length > 0) {
        adobe.searchResultRanking = parseInt(expert.relevancyDetails.position, 10) + 1;
      } else if (!expert?.relevancyDetails?.position) {
        adobe.searchResultRanking = NO_VAR;
      }
    }
    window.digitalData = Object.assign(window.digitalData, adobe);
    PAGE_ANALYTICS.track('Topic_Tag_Info');
    delete window.digitalData.search.bestbetflag;
    delete window.digitalData.expertTagType;
  },
};