import BRANDS from 'config-brands';
import FILTERS from 'config-filters';

const PROFILE_SOURCE = 'nav';
/* API Enrivonment URLs documented https://bcg-ksg.atlassian.net/wiki/spaces/EAD/pages/609714232/Enrich+API+Environment+URLs
Our Dev and QA environments should always reference the QA API environment. Do not use Dev unless directed by an
API developer, and that change should not persist to minerva-dev or minvera-qa */
const CONFIG = {
  BRANDS,
  ...FILTERS,
  SERVER_URL: process.env.REACT_APP_FRONTEND_URL,
  ALTERNATE_SERVER_URL: process.env.REACT_APP_ALTERNATE_FRONTEND_URL,
  X_API_KEY: process.env.REACT_APP_X_API_KEY,
  DOC_VIZ_X_API_KEY: process.env.REACT_APP_DOC_VIZ_X_API_KEY, // THIS KEY IS NEEDED FOR STORAGE ENDPOINT
  PEOPLE_API_X_API_KEY: process.env.REACT_APP_PEOPLE_API_X_API_KEY,
  CLIPPING_API_X_API_KEY: process.env.REACT_APP_CLIPPING_API_X_API_KEY,
  DATA_SCIENCE_API_X_API_KEY: process.env.REACT_APP_DATA_SCIENCE_X_API_KEY,
  EXPERT_SEARCH_X_API_KEY: process.env.REACT_APP_EXPERT_SEARCH_X_API_KEY,
  ENVIRONMENT: process.env.REACT_APP_ENV,
  DATA_DOG: {
    APPLICATION_ID: process.env.REACT_APP_DATADOG_RUM_APPID,
    CLIENT_TOKEN: process.env.REACT_APP_DATADOG_RUM_CLIENTTOKEN
  },
  REACT_APP_PRACTICE_AREAS_URL: process.env.REACT_APP_PRACTICE_AREAS_URL,
  REACT_APP_BINARY_MAPPING: process.env.REACT_APP_BINARY_MAPPING,
  REACT_APP_CASE_TEAM_SERVICES_URL: process.env.REACT_APP_CASE_TEAM_SERVICES_URL,
  API_URL: {
    SEARCH_CONFIG: `${process.env.REACT_APP_API_URL}/v1/searchconfig/getconfig`,
    KNOWLEDGE_DOWNLOAD_FILE: (guid) => `${process.env.REACT_APP_KNOWLEDGE_DOWNLOAD_URL}/download/kp/SmartSearch/Knowledge_Page/${guid}`,
    // delete when old materials tab is officially removed
    TYPE_AHEAD: (searchTerm) => `${process.env.REACT_APP_API_URL}/v1/typeaheadsearch${searchTerm}&pageSize=14`,
    KNOWLEDGE_TYPE_AHEAD: (searchTerm) => `${process.env.REACT_APP_API_URL}/knowledge-type-ahead/v1/${searchTerm}`,
    INTERNAL_TYPE_AHEAD: (searchTerm) => `${process.env.REACT_APP_API_URL}/internal-type-ahead/v1/${searchTerm}`,
    SLIDE_SEARCH: (queryString, attachmentId, matchMode) => `${process.env.REACT_APP_API_URL}/v1/slidelevelsearch?${queryString ? `query=${queryString}` : 'query=\'\''}&attachmentID=${attachmentId}&resultsPerPage=100000&matchMode=${matchMode}`,
    SLIDE_LEVEL_SEARCH: (queryString, guid, matchMode) => `${process.env.REACT_APP_API_URL}/slidelevelsearch/v3?${queryString ? `query=${queryString}` : 'query=\'\''}&guid=${guid}&resultsPerPage=100000&matchMode=${matchMode}`,
    NGEN_FETCH_CLIPPING_ID: `${process.env.REACT_APP_API_CLIPPING_URL}/clipfile/clippingrequest?appName=SmartSearch`,
    NGEN_FETCH_CLIPPED_FILE: (clippingId) => `${process.env.REACT_APP_API_CLIPPING_URL}/clipfile/status/${clippingId}?appName=SmartSearch`,
    DOWNLOAD_REQUEST_CASES: `${process.env.REACT_APP_API_EXPORT_URL}/export/v1/cases/request`,
    DOWNLOAD_PROGRESS_CASES: (downloadId) => `${process.env.REACT_APP_API_EXPORT_URL}/export/v1/cases/status?id=${downloadId}`,
    CLIPPED_EMAIL_LINK: (kpId, pageNumbers, attachmentName) => `https://${process.env.REACT_APP_FRONTEND_URL}/slideDownload?kpId=${kpId}&slides=${pageNumbers}&fileName=${attachmentName}`,
    STORAGE: (name) => `${process.env.REACT_APP_API_URL}/v1/storage/${name}`,
    TOPIC_EXPERTS: (topicId) => `${process.env.REACT_APP_API_URL}/v1/topicsuggestions/${topicId}/experts`,
    TOPIC_EXPERTS_MULTIPLE: (topicIds) => `${process.env.REACT_APP_API_URL}/v1/topicsuggestions/experts?topicIds=${topicIds}`,
    PINNED_DOCUMENTS: `${process.env.REACT_APP_API_URL}/v1/pinneddocuments`,
    PINNED_DOCUMENTS_DELETE: `${process.env.REACT_APP_API_URL}/v1/pinneddocuments/delete`,
    FETCH_SHORTER_URL: `${process.env.REACT_APP_API_URL}/v1/urlshort`,
    PEOPLE: `${process.env.REACT_APP_API_PEOPLE}/people`,
    LOCATIONS: `${process.env.REACT_APP_API_PEOPLE}/location`,
    CASE_SEARCH: (queryString) => `${process.env.REACT_APP_API_URL}/case-search/v1/search${queryString}`,
    CASE_SEARCH_V2: (queryString) => `${process.env.REACT_APP_API_URL}/case-search/v2/search${queryString}`,
    MATERIAL: (docid) => `${process.env.REACT_APP_API_URL}/knowledge-search/v1/search?query=${docid}`,
    KNOWLEDGE_SEARCH: (queryString) => `${process.env.REACT_APP_API_URL}/knowledge-search/v1/search${queryString}`,
    KNOWLEDGE_BEST_BET: `${process.env.REACT_APP_API_URL}/knowledge-search/v1/bestbet`,
    EXPERT_SEARCH: (path) => `${process.env.REACT_APP_EXPERT_SEARCH_URL}/expertsearch/${path}`,
    BCG_INTERNAL_SEARCH: (queryString) => `${process.env.REACT_APP_API_URL}/internal-search/v2/search${queryString}`,
    CASE_TYPEAHEAD: (searchTerm) => `${process.env.REACT_APP_API_URL}/case-type-ahead/v1/?query=${searchTerm}`,
    CASE_TYPEAHEAD_V2: (searchTerm) => `${process.env.REACT_APP_API_URL}/case-type-ahead/v2/?query=${searchTerm}`,
    META_DATA: (attachmentId) => `${process.env.REACT_APP_API_URL}/v1/metadata?attachmentID=${attachmentId}`,
    LAB_SEARCH: (queryString) => `${process.env.REACT_APP_API_URL}/v2/lab-search${queryString}`,
    LAB_DETAILS: (queryString, labCourseId) => `${process.env.REACT_APP_API_URL}/v2/labdetails?${queryString ? `query=${queryString}&` : ''}labCourseId=${labCourseId}`,
    GENAI_RETRIEVAL: `${process.env.REACT_APP_API_URL}/genai-retrieval/v1/search/`,
    GENAI_CUSTOMER_DATA: `${process.env.REACT_APP_API_URL}/genai-customer-data/v1/customer/abbr/`,
    GENAI_CHAT_HISTORY_TITLE: `${process.env.REACT_APP_API_URL}/genai-chathistory/v1/chattitle/update/`,
    GENAI_CHAT_HISTORY: `${process.env.REACT_APP_API_URL}/genai-chathistory/v1/chathistory/`,
    GENAI_CHAT: `${process.env.REACT_APP_API_URL}/genai-chat/${process.env.REACT_APP_CHAT_API_VERSION}/chat/`,
    OMNIA_PRODUCTS: process.env.REACT_APP_OMNIA_PRODUCTS_URL.trim(),
    PRACTICES: process.env.REACT_APP_PRACTICES_URL.trim(),
    HOMEPAGE_LINKS: `${process.env.REACT_APP_HOMEPAGE_LINKS_URL}`.trim(),
    MICROSITES: `${process.env.REACT_APP_MICROSITES_URL}`.trim(),
    OFFICEPAGES: `${process.env.REACT_APP_OFFICEPAGES_URL}`.trim(),
    HELPFUL_LINKS: `${process.env.REACT_APP_HELPFUL_LINKS_URL}`.trim(),
    AI_PILOT_USERS: `${process.env.REACT_APP_AI_PILOT_USERS}`.trim(),
    CASEFIELDS: `${process.env.REACT_APP_CASEFIELDS_URL}`.trim(),
    FILTER_TYPEAHEAD_LOAD: `${process.env.REACT_APP_API_URL}/type-ahead-filter/v1/load`,
    FILTER_TYPEAHEAD_SEARCH: (cacheId, query) => `${process.env.REACT_APP_API_URL}/type-ahead-filter/v1/search?cacheId=${cacheId}&query=${query}`,
    QUERY_SUGGESTIONS: `${process.env.REACT_APP_DATA_SCIENCE_URL}/v1/suggest`
  },
  KN_URL: {
    PROFILE_SOURCE,
    AUTHOR_PROFILE: (hrEmployeeId) => `${process.env.REACT_APP_BCG_PROFILES}/overview?hrid=${hrEmployeeId}&source=${PROFILE_SOURCE}`,
    CASE_URL: (projectId) => process.env.REACT_APP_CASE_DETAIL_URL.toLowerCase().replace('{projectid}', projectId)
  },
  CMS: {
    KP_URL: (guid) => `${process.env.REACT_APP_CMS_URL}/kp/${guid}`,
    KB_URL: (guid) => `${process.env.REACT_APP_CMS_URL}/kb/${guid}`,
    METADATA: (guid) => `${process.env.REACT_APP_CMS_METADATA_URL}/api/Knowledge/Page/${guid}/Docviz`,
    METADATA_XAPIKEY: process.env.REACT_APP_CMS_METADATA_XAPIKEY,
    MICROSITES: 'https://internal.bcg.com/microsites',
    OFFICEPAGES: 'https://internal.bcg.com/offices'
  },
  APP_NAME: 'Navigator',
  AUTHOR_EMAIL: (email) => `mailto:${email}`,
  PEOPLE_IMAGE: (hrId) => `${process.env.REACT_APP_API_PEOPLE}/people/picture?hrid=${hrId}`,
  LAB_LEARNING_OPEN: (learningId) => `${process.env.REACT_APP_BCG_LAB}/categorydetail/${learningId}`,
  LAB_PROGRAM_OPEN: (programId) => `${process.env.REACT_APP_BCG_LAB}/learningeventdetail/${programId}`,
  LAB_COURSE_OPEN: (courseId) => `${process.env.REACT_APP_BCG_LAB}/leclassview/${courseId}`,
  LAB_CHAPTER_OPEN: (labTopicIdUrl, labChapterId) => `${labTopicIdUrl}&ChapterID=${labChapterId}`,
  LAB_CHAPTER_PLAYLIST: (labChapterId) => `${process.env.REACT_APP_BCG_LEARNING}/Actions/AddToMyPlaylist?Source=EMAIL_SHARE&ContentType=Chapter&ContentID=${labChapterId}`,
  LAB_CHAPTER_SHARE: (labChapterId) => `${process.env.REACT_APP_BCG_LEARNING}/Actions/Share?Source=EMAIL_SHARE&ContentType=Chapter&ContentID=${labChapterId}`,
  LAB_LESSON_SHARE: (lessonContentId) => `${process.env.REACT_APP_BCG_LEARNING}/Actions/Share?Source=SMARTSEARCH&ContentType=Lesson&ContentID=${lessonContentId}`,
  LAB_LESSON_PLAYLIST: (lessonContentId) => `${process.env.REACT_APP_BCG_LEARNING}/Actions/AddToMyPlaylist?Source=SMARTSEARCH&ContentType=Lesson&ContentID=${lessonContentId}`,
  LAB_COURSE_PLAYLIST: (courseId) => `${process.env.REACT_APP_BCG_LEARNING}/Actions/AddToMyPlaylist?Source=SMARTSEARCH&ContentType=Course&ContentID=${courseId}`,
  LAB_COURSE_EMAIL: (courseId) => `${process.env.REACT_APP_BCG_LEARNING}/Actions/Share?Source=SMARTSEARCH&ContentType=Course&ContentID=${courseId}`,
  MATERIAL_URL: (guid) => `/material/${guid || ''}`,
  CASE_SEARCH_V2: (term, attachmentId, showDocViz, materialId) => `/case?query=${term}${attachmentId ? `&attachmentId=${attachmentId}` : ''}${showDocViz ? '&showDocViz=true' : ''}`,
  SLACK_URL: (slackId) => `https://bcg.enterprise.slack.com/user/@${slackId}`,
  SMART_SEARCH_VERSION: process.env.REACT_APP_SS_VERSION,
  OKTA: {
    ISSUER: process.env.REACT_APP_OKTA_ISSUER,
    REDIRECT_URL: '/implicit/callback' /* Must be whitelisted in the Okta client app settings. */,
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    ID_TOKEN_STORAGE: 'okta-token-storage_idToken',
    ACCESS_TOKEN_STORAGE: 'okta-token-storage_accessToken',
    PSID: 'psid',
    AUTH_ATTEMPTED: 'auth_attempted'
  },
  UI_URL: {
    ALL: '*',
    HOME: '/',
    CHAT: '/chat',
    CASE: '/case',
    PEOPLE: '/people',
    EXPERTS: '/experts',
    MATERIAL: '/material',
    PA_TOPICS: '/pa-topics',
    KNOWLEDGE: '/knowledge',
    PROPOSALBUILDER:'/proposalbuilder',
    PROPOSALBUILDER_DETAILS: (id = '') => `/proposalbuilder/details${id ? `/${id}` : ''}`,
    BANNER: 'https://pages.navigator.bcg.com/kb/preview/d1f983e0-e4d9-4b37-96ec-fb3a886ff047',
    LAB: '/lab',
    BCG_INTERNAL: '/bcginternal',
    ERROR: (queryString) => `/error/${queryString}`,
    ERROR_WITH_SOURCE: (queryString) => `/error/${queryString}${encodeURI(window.location.pathname)}${encodeURI(window.location.search)}`,
    AUTH: '/implicit/callback', /* Must be whitelisted in the Okta client app settings. */
    ANALYTICS_UTIL: '/analytics'
  },
  SEARCH: {
    SCOPES: {
      KNOWLEDGE: 'knowledge',
      PA_TOPICS: 'pa_topics',
      LAB: 'lab',
      CASE: 'case',
      PEOPLE: 'people',
      BCG_INTERNAL: 'bcg_internal', // This has to sync with CONFIG.SEARCH.SCOPES.BUTTONS.BCG_INTERNAL.scope
      EXPERTS: 'experts',

      BUTTONS: {
        OLD_MATERIALS: {
          title: 'Knowledge',
          scope: 'material',
        },
        MATERIALS: {
          title: 'Materials',
          scope: 'knowledge',
        },
        PA_TOPICS: {
          title: 'PA Pages',
          scope: 'pa_topics',
        },
        CASES: {
          title: 'Cases',
          scope: 'case',
        },
        DIRECTORY: {
          title: 'Directory',
          scope: 'people',
        },
        EXPERTS: {
          title: 'Experts',
          scope: 'experts'
        },
        LAB: {
          title: 'LAB',
          scope: 'lab',
        },
        BCG_INTERNAL: {
          title: 'BCG Internal',
          scope: 'bcg_internal' // This has to sync with CONFIG.SEARCH.SCOPES.BCG_INTERNAL
        }
      },
    },
    GENAI_RETRIEVAL_TOP_N: process.env.REACT_APP_GENAI_RETRIEVAL_TOP_N,
    AI_BANNER: {
      knowledge: 'AI View allows you to find specific slides from PPT and PDF decks by searching with natural language. Try it out!',
      experts: 'Introducing AI-powered Expert Search: Search with natural language queries to discover the right experts with ease.'
    },
    NAVI_POPUP_CONTENTS:{
      experts:'Chat to discover BCG experts in the context of your case or business development needs'
    },
    CHAT_BANNER: {
      message1: 'Currently, Navi only has access to information found on the Materials and Experts tab within Knowledge Navigator, with a knowledge cutoff of October 2023.',
      message2: ' to learn more about Navi\'s capabilities and limitations!'
    },
  },
  CASE_TEAM: { MAX_CALL_TO_PEOPLE_API: 5 },
  TYPEAHEAD: { // objects should match what's in type-ahead.js, put in display order
    KNOWLEDGE: {
      MAX_LENGTH: 7,
      MAX_LENGTH_MOBILE: 3,
      LABEL: 'Knowledge',
      URL: '/knowledge',
      DISPLAY_ORDER: 1,
      SEARCH_TEXT: 'Search Knowledge',
      BRAND_SEARCH_PARAM: 'KNOWLEDGE', // matches the tabList
      CATEGORIES: {
        CONCEPT: {
          VALUE: 'concepts',
          LABEL: 'Materials',
          ORDER: 1,
          MAX_LENGTH: 2
        },
        ACRONYM: {
          VALUE: 'acronyms',
          LABEL: 'Acronyms',
          ORDER: 2,
          MAX_LENGTH: 2
        },
        AUTHOR: {
          VALUE: 'authors',
          LABEL: 'Authors',
          ORDER: 3,
          MAX_LENGTH: 2
        },
        FILENAME: {
          VALUE: 'filenames',
          LABEL: 'Files',
          ORDER: 4,
          MAX_LENGTH: 2
        }
      }
    },
    PA_TOPICS: {
      MAX_LENGTH: 7,
      MAX_LENGTH_MOBILE: 3,
      LABEL: 'PA Pages',
      URL: '/pa-topics',
      DISPLAY_ORDER: 2,
      SEARCH_TEXT: 'Search PA Pages',
      BRAND_SEARCH_PARAM: 'PA_TOPICS', // matches the tabList
      CATEGORY: 'patopics'
    },
    LAB: {
      MAX_LENGTH: 7,
      MAX_LENGTH_MOBILE: 3,
      LABEL: 'LAB',
      URL: '/lab',
      DISPLAY_ORDER: 1,
      SEARCH_TEXT: 'Search LABS',
      BRAND_SEARCH_PARAM: 'LAB', // matches the tabList
      CATEGORIES: {
        CONCEPT: {
          VALUE: 'concepts',
          LABEL: 'Materials',
          ORDER: 1,
          MAX_LENGTH: 2
        },
        ACRONYM: {
          VALUE: 'acronyms',
          LABEL: 'Acronyms',
          ORDER: 2,
          MAX_LENGTH: 2
        },
        AUTHOR: {
          VALUE: 'authors',
          LABEL: 'Authors',
          ORDER: 3,
          MAX_LENGTH: 2
        },
        FILENAME: {
          VALUE: 'filenames',
          LABEL: 'Files',
          ORDER: 4,
          MAX_LENGTH: 2
        }
      }
    },
    CASE: {
      MAX_LENGTH: 7,
      MAX_LENGTH_MOBILE: 3,
      LABEL: 'Cases',
      URL: '/case',
      DISPLAY_ORDER: 2,
      SEARCH_TEXT: 'Search Cases',
      BRAND_SEARCH_PARAM: 'CASE', // matches the tabList
      CATEGORY: 'client name, case number, concepts',
    },
    PEOPLE: {
      MAX_LENGTH: 7,
      MAX_LENGTH_MOBILE: 3,
      LABEL: 'Directory',
      URL: '/people',
      DISPLAY_ORDER: 3,
      SEARCH_TEXT: 'Search Directory',
      BRAND_SEARCH_PARAM: 'PEOPLE', // matches the tabList
      CATEGORY: 'people'
    },
    BCG_INTERNAL: {
      MAX_LENGTH: 7,
      MAX_LENGTH_MOBILE: 3,
      LABEL: 'BCG Internal',
      URL: '/bcginternal',
      DISPLAY_ORDER: 4,
      SEARCH_TEXT: 'Search Internal Materials',
      BRAND_SEARCH_PARAM: 'BCG_INTERNAL', // matches the tabList
      CATEGORY: 'bcginternal'
    },
    EXPERTS: {
      MAX_LENGTH: 7,
      MAX_LENGTH_MOBILE: 3,
      LABEL: 'Experts',
      URL: '/experts',
      DISPLAY_ORDER: 5,
      SEARCH_TEXT: 'AI-Powered search! Try this example, "I am looking for experts in insurance with experience in climate and sustainability."',
      BRAND_SEARCH_PARAM: 'EXPERTS', // matches the tabList
      CATEGORY: 'experts',
      NAVI_POPUP_CONTENTS: 'Chat to discover BCG experts in the context of your case or business development needs'
    },
    RECENT_SEARCHES: {
      MAX_LENGTH: 7,
      MAX_LENGTH_MOBILE: 3,
      LABEL: 'Recent Searches',
      DISPLAY_ORDER: 1,
      SEARCH_TEXT: 'Search Materials',
    }
  },
  CASE_VIEW: {
    LIST: 'list',
    EXPANDED: 'expanded',
    CASETEAMCOUNT: 5
  },
  KNOWLEDGE_VIEW: {
    LIST: 'list',
    EXPANDED: 'expanded'
  },
  KNOWLEDGE: {
    KB_AUTHOR_TITLE: 'Created By',
    KP_AUTHOR_TITLE: 'Primary Author',
    BUNDLE: 'Bundle',
    TOPIC_COLLECTION: 'Topic Collection',
    PAGE: 'PAGE',
    TOPIC_COLLECTION_SOURCE: 'src_TC',
    KNOWLEDGE_SOURCE: 'src_Knwl',
    KNOWLEDGE_RESULT_TYPES: { BUNDLE_URL: 'KBU', PAGE_URL: 'KPU' },
    DEFAULT_LANGUAGE: 'English'
  },
  BCG_INTERNAL: { SOURCE: 'src_Int' },
  PEOPLE_VIEW: { PHONEBOOK: 'phonebook' },
  DEFAULT_RECENT_SEARCH_COUNT: 8,
  DEFAULT_AUTO_CORRECT: true,
  KNOWLEDGE_SORTERS: [
    {
      id: 'relevance.desc',
      display: 'Relevancy',
      resultProperty: 'relevancyScore'
    },
    {
      id: 'Original_Published_Date.desc',
      display: 'Date Published',
      resultProperty: 'datePublished'
    },
    {
      id: 'dateRevised.desc',
      display: 'Date Revised',
      resultProperty: 'dateRevised'
    },
    {
      id: 'downloads.desc',
      display: 'Downloads',
      resultProperty: 'downloads'
    }
  ],
  EXPERT_SORTERS: [
    {
      id: '_score',
      display: 'Relevancy',
      resultProperty: '_score'
    },
    {
      id: 'caseHours',
      display: 'Case Hours',
      resultProperty: 'caseHours'
    }
  ],
  CASE_SORTERS: [
    {
      id: 'relevance.desc',
      display: 'Relevancy',
      resultProperty: 'relevancyScore'
    },
    {
      id: 'dateOpened.desc',
      display: 'Date Opened',
      resultProperty: 'dateOpened'
    }
  ],
  DEFAULT_SORTERS: {
    KNOWLEDGE: 'relevance.desc',
    CASE: 'dateOpened.desc',
    BCG_INTERNAL: 'relevance.desc',
    EXPERT: '_score'
  },
  SEARCH_HINTS: [
    {
      TITLE: 'Digital <em>NOT</em> Insurance',
      MESSAGE: 'Using NOT excludes materials with that keyword, for example materials that contain digital but not insurance',
      ANALYTICS_KEY: 'Not Operator'
    },
    {
      TITLE: 'Digital <em>OR</em> Technology',
      MESSAGE: 'Try digital OR technology to include materials that contain the keyword digital or materials with keyword technology',
      ANALYTICS_KEY: 'Or Operator'
    },
    {
      TITLE: '"Digital Transformation"',
      MESSAGE: 'Use quotes to find an exact phrase such as "digital transformation"',
      ANALYTICS_KEY: 'Quotes Operator'
    },
    {
      TITLE: 'Digital <em>AND</em> Technology',
      MESSAGE: 'Using digital AND technology finds materials that contains both keywords digital and technology',
      ANALYTICS_KEY: 'AND Operator'
    },
    {
      TITLE: 'Data <em>OR</em> Digital <em>AND</em> Strategy',
      MESSAGE: 'Use multiple boolean operators to create more specific searches. For example, materials with the keyword Data or Digital and the keyword Strategy',
      ANALYTICS_KEY: 'Both AND + Or Operator'
    },
    {
      TITLE: '“Digital Transformation" <em>AND</em> Data',
      MESSAGE: 'Get specific by finding exact matches using quotations. For example, materials that contain exact matches for “Digital Transformation”',
      ANALYTICS_KEY: 'Both AND + Quotes Operator'
    }
  ],
  RELEVANCY: {
    TEXT: {
      QUESTIONS: {
        HOW: (searchTerm) => `How relevant is this result to your search for ${searchTerm}?`,
        RELATED_SUGGESTION_HOW: 'Is this file helpful in finding what you are looking for?',
        HOW_NOTERM: 'How relevant is this result to your search?',
        RATE: (indicator) => `Why did you rate it ${indicator}?`,
        OTHER_PLACEHOLDER: 'Provide a reason for your rating'
      },
      THANK_YOU: {
        TITLE: 'Thank you!',
        MESSAGE: 'Your feedback will help improve search results'
      }
    },
    INDICATOR: (indicator) => [
      {
        CLASS: 'not',
        TOOLTIP: `not at all ${indicator}`,
        ISPOSITIVE: false
      },
      {
        CLASS: 'somewhat',
        TOOLTIP: `somewhat ${indicator}`,
        ISPOSITIVE: false
      },
      {
        CLASS: 'mostly',
        TOOLTIP: `mostly ${indicator}`,
        ISPOSITIVE: true
      },
      {
        CLASS: 'highly',
        TOOLTIP: `highly ${indicator}`,
        ISPOSITIVE: true
      }
    ],
    ANSWERS: {
      // use ID for sorting and uniqueness
      CASE: [
        {
          ID: 1,
          TEXT: 'Case publication date'
        },
        {
          ID: 2,
          TEXT: 'Search term match with case'
        },
        {
          ID: 3,
          TEXT: 'Match with BCG specific terms'
        },
        {
          ID: 4,
          TEXT: 'Other',
          OTHER: true
        }
      ],
      MATERIALS_AND_LABS: [
        {
          ID: 1,
          TEXT: 'Document publication/update date'
        },
        {
          ID: 2,
          TEXT: 'Search term match with document'
        },
        {
          ID: 3,
          TEXT: 'Match with BCG specific terms'
        },
        {
          ID: 4,
          TEXT: 'Other',
          OTHER: true
        }
      ]
    }
  },
  QUERY_PARAMS: {
    QUERY: 'query',
    SORTING_ORDER: 'sortingOrder',
    RESULTS_PER_PAGE: 'resultsPerPage',
    DATE_OPENED: 'dateOpened',
    ENABLE_AUTO_CORRECT: 'enableAutoCorrect',
    SLIDES: 'slides',
    MATERIAL_ID: 'materialId',
    ATTACHMENT_NAME: 'attachmentName',
    ATTACHMENT_ID: 'attachmentId',
    AUTHOR: 'author',
    FUNCTIONAL_PA: 'functionalPA',
    INDUSTRY_PA: 'industryPA',
    MATERIAL_TYPE: 'materialType',
    DATE_CLOSED: 'dateClosed',
    DATEPUBLISHED: 'datePublished',
    STARTDATEPUBLISHED: 'startDatePublished',
    ENDDATEPUBLISHED: 'endDatePublished',
    DOCUPDATEDDATERANGE: 'dateRevised',
    STARTDATEREVISED: 'startDateRevised',
    ENDDATEREVISED: 'endDateRevised',
    STARTDATEOPENED: 'startDateOpened',
    ENDDATEOPENED: 'endDateOpened',
    TRAININGANDTOOLS: 'trainingAndTools',
    PA_RECOMMENDED: 'recommendedBy',
    SUBJECT_PA: 'subjectPA',
    ADV_SEARCH: 'advSearch',
    CASETEAMMEMBERWITHHRID: 'caseTeamMemberHrId',
    BCG_HRID: 'hrId',
    LANGUAGES: 'language',
    BCGOFFICENAME: 'office',
    BCGREGIONCOUNTRYNAME: 'region',
    DOCUMENTFORMAT: 'documentFormat',
    ALUMNI: 'alumni',
  },
  CASE_QUERY_PARAMS: {
    QUERY: 'query',
    SORTING_ORDER: 'sortingOrder',
    FUNCTIONAL_PA: 'functionalPA',
    INDUSTRY_PA: 'industryPA',
    CASE_TYPE: 'caseType',
    DATE_OPENED: 'dateOpened',
    DATE_CLOSED: 'dateClosed',
    CLIENT_NAME: 'clientName',
    VIGNETTES: 'vignettes',
    ENABLE_AUTO_CORRECT: 'enableAutoCorrect',
    CASETEAMMEMBERWITHHRID: 'caseTeamMemberHrId',
    REGION: 'region',
    OFFICE_NAME: 'office',
    ADV_SEARCH: 'advSearch',
    STARTDATEOPENED: 'startDateOpened',
    ENDDATEOPENED: 'endDateOpened',
    SUBJECT_PA: 'subjectPA',
    DATE_RANGE: 'dateRange' //That comes from shared-search-typeahead, shared-global-nav
  },
  EXPERT_QUERY_PARAMS: {
    SORTING_ORDER: 'sortingOrder',
    EXPERT_TYPE: 'expertType',
    ALUMNI: 'includeAlumni',
    BCGREGIONOFFICE: 'hostLocation',
    JOBFAMILYGROUP: 'jobFamilyGroup',
    ORGANIZATION: 'organization',
    industryPracticeArea: 'industryPracticeArea',
    functionalPracticeArea: 'functionalPracticeArea',
    subjectArea: 'subjectArea',
    paAffiliation: 'paAffiliation',
    paLeadership: 'paLeadership',
    caseDate: 'caseDate',
    workCompany: 'workCompany',
    skills: 'skills',
    toolsAndSolutions: 'toolsAndSolutions',
    language: 'language',
    university: 'university',
    onlyCertifiedProfiles: 'onlyCertifiedProfiles',
    onlyCCOProfiles: 'onlyCCOProfiles'
  },
  DEFAULT_CURRENT_PAGE: 1,
  SEARCH_FLAVORS: {
    UNKNOWN: 'unknown',
    OTHER_ATTACHMENTS: 'other_attachments',
    SIDE_TABS: 'side_tabs',
    GRID_VIEW: 'grid_view',
    LIST_VIEW: 'list_view',
    AI_VIEW: 'ai_view',
    NAVI: 'navi',
    CARDS: 'cards',
    LAB_MODAL: 'lab_modal',
    TOPIC_SUGGESTION: 'topic_suggestion',
    TOPIC_EXPANDED: 'topic_experts_expanded',
    RELATED_SUGGESTIONS: 'related_suggestions',
    PEOPLE: 'people_search',
    CASE: 'case',
    LAB: 'lab',
    KNOWLEDGE_PAGE: 'knowledge_page',
    KNOWLEDGE_BUNDLE: 'knowledge_bundle'
  },
  FEEDBACK_FLAVOR: {
    TYPEAHEAD: 'typeahead',
    RELATED_CONCEPTS: 'related_concepts',
  },
  LAB_FLAVORS: {
    UNKNOWN: 'unknown',
    TALK: 'talk',
    COURSE: 'course',
    CHAPTER: 'chapter',
    LESSON: 'lesson'
  },
  AUTHOR_ACTION_FLAVORS: {
    PROFILE: 'profileButton',
    EMAIL: 'emailButton',
    SLACK: 'slackButton'
  },
  REQUEST_FLAVORS: {
    DARK: 'dark',
    LIGHT: 'light'
  },
  EXPORT: {
    FILENAME: {
      BCG_INTERNAL: 'BCG_Internal_Export',
      CASES: 'Cases_Export',
      EXPERTS: 'Experts_Export',
      MATERIALS: 'Materials_Export',
      PEOPLE: 'Directory_Export'
    },
    MAPPINGS: {
      CASES: new Map([
        ['source', 'Content Type'],
        ['caseType', 'Case Type'],
        ['caseNumber', 'Case Number'],
        ['caseOpenDate', 'Date Opened'],
        ['caseCloseDate', 'Date Closed'],
        ['officeName', 'Office'],
        ['geoRegion', 'Region'],
        ['clientName', 'Client Name'],
        ['clientDescription', 'Client Description'],
        ['clientBUstring', 'Client BU Name'],
        ['shortDescription', 'Short Description'],
        ['textDescription', 'Full Description'],
        ['industryAllPAs', 'Industry'],
        ['functionalAllPAs', 'Function'],
        ['allSubjects', 'Subject Area'],
        ['allCaseTeams', 'Case Team'],
        ['currentPartner', 'Current Partner'],
        ['totalHours', 'Total Hours'],
        ['projectId', 'URL']
      ]),
      EXPERTS: new Map([
        ['firstName', 'First Name'],
        ['lastName', 'Last Name'],
        ['businessTitleString', 'Business Title'],
        ['jobFamilyGroupString', 'Department'],
        ['expertTypeString', 'Expert Type'],
        ['bcgOrganizationString', 'BCG Organization'],
        ['hostOfficeString', 'Office Name'],
        ['regionString', 'Region'],
        ['profileURLstring', 'Link to Profile URL'],
        ['profileSummaryString', 'Profile Summary'],
        ['bcgEmailAddressString', 'Email'],
        ['businessMobileString', 'Phone'],
        ['emailAssistantString', 'Email Assistant'],
        ['companiesPriorToJoiningBCGString', 'Pre-BCG Organizations'],
        ['titlesPriorToJoiningBCGString', 'Pre-BCG Titles'],
        ['relatedCasesString', 'Related Cases to *search term* (Navigator Case search URL with search term as query)'],
        ['relatedMaterialsString', 'Related Materials to *search term* (Navigator Material search URL with search term as query)']
      ]),
      MATERIALS: new Map([
        ['source', 'Content Type'],
        ['title', 'Title Description'],
        ['modified', 'Updated Date'],
        ['docType', 'Material Type'],
        ['materialDesc', 'Material Description'],
        ['industryAllPAs', 'Industries'],
        ['functionalAllPAs', 'Functions'],
        ['allSubjects', 'Subject Area'],
        ['recommendedByPA', 'Recommended By'],
        ['allAuthors', 'Author List '],
        ['fileName', 'Filename'],
        ['url', 'Knowledge URL '],
        ['officeName', 'Office Name'],
        ['allDownloads', 'Downloads'],
        ['kbCmsId', 'Hidden'],
        ['kpCmsId', 'Hidden'],
        ['fpaRecommendedList', 'Hidden'],
        ['ipaRecommendedList', 'Hidden'],
      ]),
      PEOPLE: new Map([
        // [Field Name, Table Column Name]
        ['name', 'Name'],
        ['alumni', 'Alumni'],
        ['title', 'Title'],
        ['hostOfficeLocationName', 'Office'],
        ['emailString', 'Email'],
        ['phoneString', 'Phone'],
        ['mobileString', 'Mobile'],
        ['assistantsString', 'Assistant']
      ])
    },
    COLUMNS_WIDTH: {
      // Array of number of characters mapping to corresponding MAPPINGS above.
      EXPERTS: [30, 30, 60, 60, 60, 60, undefined, undefined, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60],
      PEOPLE: [30, 6, 62, 30, undefined, undefined, undefined, 30]
    }
  },
  FILTERS: {
    CONCEPTS: { QUERY_PARAM: 'bcgConcept' },
    RECOMMENDED_BY: {
      QUERY_PARAM: 'recommendedBy',
      METADATA: { QUERY_VALUE: { PA_RECOMMENDED: 'PA/Topic' } }
    }
  },
  WHITE_LISTED_LINKS: ['bcg.com', 'bcgtest'],
  RESULT_TYPES: {
    MATERIAL: 'MM',
    URL: 'MU',
    ATTACHMENT: 'MA',
    LAB_COURSE: 'LC',
    LAB_TALK: 'LT',
    LAB_PLAYLIST: 'LP',
    KNOWLEDGE_PAGE: 'KPA',
    KNOWLEDGE_BUNDLE: 'KBU'
  },
  DISPLAY_MODE: {
    LIST: 'list',
    GRID: 'grid',
    EXPANDED: 'expanded',
    RECENT_ACTIVITY_TAB: 'Recent Activity Tab',
    TOPIC_EXPANDED: 'topic_experts_expand',
    ADVANCED_SEARCH: 'advanced search',
    HOME: 'Home',
    MATERIAL: 'material',
    PINNED_DOCS: 'pinned_docs',
    PHONEBOOK: 'phonebook',
    CASE: 'case',
    LAB: 'lab',
    AI: 'ai',
    NAVI: 'navi'
  },
  DISPLAY_TEXT: {
    400: {
      TITLE: 'We are sorry, Bad Request',
      MESSAGE: 'Something went wrong, please click BACK TO HOMEPAGE below and try again.',
      CODE: '400'
    },
    401: {
      CODE: '401',
      TITLE: 'We are sorry, you do not have sufficient privileges',
      MESSAGE: 'Please click the “Refresh” button below to try again.',
      SECOND_TRY: 'Our support team has been notified of this error.\nIn the meantime, please try restarting your browser and trying again'
    },
    403: {
      CODE: '403',
      TITLE: 'We are sorry, Page Forbidden',
      MESSAGE: 'The page was valid, but the server is refusing action. The user might not have the\nnecessary permissions or a resource, or may need an account of some sort.'
    },
    404: {
      CODE: '404',
      TITLE: 'We are sorry, Page not found!',
      MESSAGE: 'The page you are looking for might have been removed, had its name changed\nor is temporarily unavailable.'
    },
    IE11: {
      CODE: 'ie11',
      TITLE: 'We are sorry, Navigator doesn\'t support Internet Explorer 11',
      MESSAGE: 'Please use Chrome or Microsoft Edge. Both can be installed from your computer\'s Software Center.'
    },
    DEFAULT_ERROR: {
      CODE: '404',
      TITLE: 'Oops, something went wrong!',
      MESSAGE: 'Something went wrong, please try again.'
    },
    451: 'The page you requested is unavailable for legal reasons.',
    ERROR_MESSAGES: {
      AUTH_FAILED: 'Authentication failed.',
      INVALID_TOKEN: 'Invalid token.',
      NOT_FOUND: 'The server could not find the data',
      FORBIDDEN: 'You do not have permissions to view this content',
      UNKNOWN_CODE: 'The server returned an unknown HTTP error code.',
      SERVER_ERROR: 'There was an external server error',
      NETWORK_ERROR: 'There was a network error.',
      REQUEST_CANCELLED: 'The request was cancelled.',
      SEARCH: {
        TITLE: 'Error retrieving search results',
        MESSAGE: 'We were unable to complete your search, please try again.'
      },
      APPLY_FILTER: {
        TITLE: 'Error applying selected filters',
        MESSAGE: 'We were unable to apply your selected filters, please try again.'
      },
      CLEAR_FILTER: {
        TITLE: 'Error clearing selected filters',
        MESSAGE: 'We were unable to clear the selected filters, please try again.'
      },
      SORT_RESULTS: {
        TITLE: 'Error sorting results',
        MESSAGE: 'We were unable to apply your selected sort order, please try again.'
      },
      RECOMMENDED: {
        TITLE: 'Error applying recommendations',
        MESSAGE: 'We were unable to apply your selected recommendation, please try again.'
      },
      CHANGE_RESULTS_VIEW: {
        TITLE: 'Error updating search results display',
        MESSAGE:
          'We were unable to change your search results display, please try again.'
      },
      INVALID_SVG_SLIDES: {
        TITLE: 'Error processing your slides',
        MESSAGE: 'We were unable to render your slides appropriately.'
      }
    },
    TOOLTIPS: { VPN_REQUIRED: '(VPN Required)' },
    RECENT_SEARCHES: {
      NO_RECENT_SEARCHES_TITLE: 'You have no recent searches.',
      NO_RECENT_SEARCHES_MESSAGE: () =>
        `Quickly access the previous searches you have made using ${CONFIG.APP_NAME}.`
    },
    EMAIL_ALL_MAX: {
      MAX_COUNT: 8,
      MESSAGE: (maxCount) => `Select up to ${maxCount} documents to share via email.`,
      EXCEEDED_MAX: 'Unable to email more than 8 items at once. Please email each item individually from the panel above.'
    },
    PINNED_DOCUMENTS: {
      PINNED_DOCUMENTS_INFO: {
        TITLE: '',
        MESSAGE: 'Quickly come back to individual search results you pin as you scan through the results. Pinned results are available within the current session, and can be retrieved in later sessions.'
      },
      PINNED_DOCUMENTS_HOME: {
        TITLE: 'You have no pinned documents',
        MESSAGE: 'Quick access to individual search results you have pinned during the current session or from prior sessions. These results are available both in the “Recent Activity” area and on the Search Results page.'
      },
      PINNED_DOCUMENTS_RESULTS: {
        TITLE: 'You have no pinned documents',
        MESSAGE: 'Quickly come back to individual search results you pin as you scan through the results. Pinned results are available within the current session, and can be retrieved in later sessions.'
      }
    },
    LOCKED_DOCUMENT: {
      PRIMARY_MESSAGE: 'Restricted access to document.',
      TOOLTIP_TITLE: 'Restricted Access',
      TOOLTIP_MESSAGE_GENERIC: 'Ask the partner or principal on your case for permission to request the document from Knowledge Help.',
      TOOLTIP_MESSAGE_MATERIAL: (materialID, materialName) => `Please ask the Partner or Principal on your case for permission to request access to the document from <a href="mailto:KnowledgeHelp@bcg.com?SUBJECT=Request for access to restricted document&BODY=Please provide me with access to the restricted materials for this record: ${materialName} %0D%0A%0D%0A(NOTE this is a link to the material detail page for the material that the restricted attachment sits within)">Knowledge Help</a>`
    },
    PA_RECOMMENDED_TOOLTIP: { MESSAGE: (topicName) => `Recommended by<br/>${topicName} Practice Area` },
    EMAIL_ATTACHMENT: {
      SINGLE_SUBJECT: (fileName) => `Shared from ${CONFIG.APP_NAME}: ${fileName}`,
      SINGLE_BODY_ATTACHMENT: (downloadUrl, materialDescription, materialPageUrl) => `Direct Download:%0D%0A${downloadUrl} %0D%0A%0D%0A${materialDescription ? `Description:%0D%0A${materialDescription}%0D%0A%0D%0A` : ''}View full details on ${CONFIG.APP_NAME}:%0D%0A https://${materialPageUrl}`,
      SINGLE_BODY_VIGNETTE: (caseType, shortDescription, casePage) => `${caseType} - ${shortDescription}%0D%0A%0D%0AView Knowledge Page: ${casePage?.startsWith('http') ? '' : 'https://'}${casePage}`,
      SINGLE_BODY_URL: (url, materialDescription, materialPageUrl) => `View:%0D%0A${url} %0D%0A%0D%0ADescription:%0D%0A${materialDescription}%0D%0A%0D%0AView full details on ${CONFIG.APP_NAME}:%0D%0A https://${materialPageUrl}`,
      SINGLE_KNOWLEDGE_BODY_URL: (url, materialDescription, materialPageUrl) => `View:%0D%0A${url} %0D%0A%0D%0ADescription:%0D%0A${materialDescription}%0D%0A%0D%0AView full details on ${CONFIG.APP_NAME}:%0D%0A ${materialPageUrl}`,
      SINGLE_BODY_MATERIAL: (materialDescription, materialPageUrl) => `${materialDescription ? `Description:%0D%0A${materialDescription}%0D%0A%0D%0A` : ''}View full details on ${CONFIG.APP_NAME}:%0D%0A ${materialPageUrl.indexOf('https://') != 0 ? 'https://' : ''}${materialPageUrl}`,
      MULTI_SUBJECT: (count) => `Shared from ${CONFIG.APP_NAME}: ${count} results`,
      MULTI_VIGNETTES_SUBJECT: (caseNumber) => `Shared from ${CONFIG.APP_NAME}: ${caseNumber !== CONFIG.CASE_RESTRICTED ? `Case Number ${caseNumber}` : 'Case'}`,
      MULTI_BODY: (index, fileName, materialPageUrl) => `${index}. ${fileName === '' ? 'Direct Download:' : fileName}%0D%0A%0D%0AView full details on ${CONFIG.APP_NAME}:%0D%0A https://${materialPageUrl}%0D%0A%0D%0A`,
      MULTI_VIGNETTES: (index, fileName, caseResultsUrl) => `${index}. ${fileName !== '' && fileName} - View Knowledge Page : ${caseResultsUrl?.startsWith('http') ? '' : 'https://'}${caseResultsUrl}%0D%0A%0D%0A`,
      CLIPPED_SLIDE_SUBJECT: (slides, attachmentName) => `Shared from ${CONFIG.APP_NAME}: ${slides.length} - ${attachmentName}`,
      CLIPPED_SLIDE_ATTACHMENT: (downloadLink, pageNumbers, materialDescription, materialPageUrl) => `Download ${pageNumbers.length} shared ${pageNumbers.length === 1 ? 'slide' : 'slides'}:%0D%0A${downloadLink} %0D%0A%0D%0ADescription:%0D%0A${materialDescription}%0D%0A%0D%0AView full details on ${CONFIG.APP_NAME}:%0D%0A https://${materialPageUrl}`,
      CLIPPED_RESTRICTED_SUBJECT: (fileName) => `Request for access to restricted document - ${fileName}`,
      CLIPPED_RESTRICTED_ATTACHMENT: (fileName, materialPageUrl) => `Please provide me with access to the document ${fileName} from - https://${materialPageUrl}`,
      CLIPPED_SLIDE_ATTACHMENT_MULTI: (index, fileName, downloadLink, pageNumbers) => `${index}. ${fileName === '' ? 'Direct Download:' : fileName}%0D%0ADownload ${pageNumbers.length} shared ${pageNumbers.length === 1 ? 'slide' : 'slides'}:%0D%0A${downloadLink} %0D%0A%0D%0A`,
    },
    DISPLAY_CONTROL_TOOLTIPS: {
      LIST: 'List View',
      EXPANDED: 'Expanded List View',
      GRID: 'Grid View',
      CASES: { VIGNETTES: 'Filter Only Cases With Vignettes' },
      AI_VIEW: 'AI View',
    },
    REFINER_CONCEPTS: {
      EMPTY_TITLE: 'Refine your results by adding in a search query',
      EMPTY_TEXT: 'Don’t see concepts? Add a search query above to see concepts related to that query.'
    },
    SLIDE_CLIPPING_TEXT: (slideNum) => `${slideNum} ${slideNum === 1 ? 'slide' : 'slides'} selected`,
    CLIP_DOWNLOAD_TEXT: 'Download in progress...',
    CLIP_ERROR_MESSAGE: {
      DOWNLOAD_FAILED_TITLE: 'Error downloading selected slides',
      DOWNLOAD_FAILED_TEXT: 'Sorry we were unable to download your selected slides, please try again',
      DOWNLOAD_COMPLETE_TITLE: 'Download completed',
      DOWNLOAD_COMPLETE_TEXT: 'Your selected slides have been downloaded successfully'
    },
    CLIP_DOWNLOAD_STATE: {
      DOWNLOAD_PREPARING_TITLE: 'Preparing your slides for download',
      DOWNLOAD_PREPARING_TEXT: 'Your download will begin automatically once your slides are ready. This may take a few seconds',
      DOWNLOAD_FINDING_TITLE: 'Finding your slides for download ',
      DOWNLOAD_COMPLETE_TITLE: 'Downloading slides',
      DOWNLOAD_COMPLETE_TEXT: () => `Your download is in progress. See what else you can discover using ${CONFIG.APP_NAME}.`,
      DOWNLOAD_RESTRICTED_TITLE: 'Restricted access to this document',
      DOWNLOAD_RESTRICTED_TEXT: 'Ask the partner or principal on your case for permission to request to '
    },
    PINNED_TAB: {
      NO_PINNED_TITLE: 'You have no pinned slides',
      NO_PINNED_TEXT: '<p>Easily access individual slides for any resuls by pinning them from Relevant or All tabs.<br>Your pinned slides will be available to you  access from your Pinned tray to easily download, email or preview once again.</p>',
      UNPIN_TEXT: 'Unpin slide',
      PIN_TEXT: 'Pin slide'
    },
    TYPE_AHEAD_FEEDBACK: {
      RELEVANCY_QUESTION: (searchTerm) => `Which of these suggestions are <strong>not</strong> relevant to your query <span class="searchWord">${searchTerm}</span>?`,
      BUTTON_TEXT: 'Provide feedback to improve search suggestions',
      THANKYOU_TITLE: 'Thank you',
      THANKYOU_TEXT: 'Your feedback will help improve search suggestions',
      MODAL_HEADING: 'Search suggestions feedback'
    },
    HOME_PAGE: {
      RR_TITLE: 'Start <em>without</em> keywords',
      RR_BUTTON_TEXT: 'Browse',
      RR_INFO_TITLE: 'When to use browse?',
      RR_INFO_TEXT: 'Use the BROWSE option when you are new to a domain or when you are not sure about the right keywords to begin your search. You will be able to add keywords later'
    },
    RELATED_CONCEPTS_FEEDBACK: {
      BUTTON_TEXT: 'Provide feedback to improve concept suggestions',
      MODAL_HEADING: 'Concepts suggestions feedback',
      ADD_ON_QUESTION: 'Provide alternate concepts <span class="not">not</span> listed',
      ADD_BUTTON: (number) => `+ Add Another (${number} left)`,
      THANKYOU_TEXT: 'Your feedback will help improve suggested concepts',
      TEXT_BOX_PLACEHOLDER: 'What suggestion were you expecting?',
      MAX_INPUTS: 3
    },
    DOWNLOAD_PROGRESS: {
      MODAL_HEADING: 'Preparing your selected pages for download…',
      TEXT: (number) => `Preparing ${number} ${number === 1 ? 'file' : 'files'}`
    },
    TOPIC_SUGGESTIONS: { TITLE: 'Products related to your search' },
    COPY_LINK: {
      MATERIAL_INFO_MESSAGE: 'Copy URL for link to material page',
      ATTACHMENT_INFO_MESSAGE: 'Copy URL for link to view attachment',
      LAB_INFO_MESSAGE: 'Copy URL for LAB'
    },
    MODERN_BROWSER: {
      TITLE: () => `For the best ${CONFIG.APP_NAME} experience, we recommend you use the latest versions of Google Chrome and Mozilla Firefox browsers.`,
      LINK_CHROME: 'Download Google Chrome',
      LINK_MOZILLA: 'Download Mozilla Firefox',
      CLOSE_TEXT: 'Cancel',
      URL_TO_DOWNLOAD_CHROME: 'https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAiA3uDwBRBFEiwA1VsajC9c963RN2nWT66E8xME9BoYIIwK295XtSjaxd8Ighq5X_ONckFhBhoCb0oQAvD_BwE&gclsrc=aw.ds',
      URL_TO_DOWNLOAD_FIREFOX: 'https://www.mozilla.org/en-US/firefox/new/',
      SESSION_STORAGE: 'modern_browser_shown'
    },
    SIDE_PANEL: {
      PRACTICE_AREAS: 'Practice Area Pages',
      OMNIA_PRODUCTS: 'BCG Products',
      VIEW_EXPERTS: 'VIEW PRACTICE AREA EXPERTS',
      LABS: 'LAB RESULTS',
      VIEW_LABS: 'View all LAB Results',
      LAB_PANEL: 'Lab Panel',
      MICROSITES: 'Microsites',
      OFFICEPAGES: 'Office Pages',
      HELPFUL_LINKS: 'Helpful Links',
      VIEW_MICROSITES: 'View Microsites Directory',
      VIEW_OFFICEPAGES: 'View Office Page Directory'
    },
    MATERIAL_PAGE: { LEGACY_MATERIAL_PAGE: 'View Original Material Page' },
    DOWNLOAD_DROPDOWN: {
      CURRENT_SLIDE: 'Current slide',
      ENTIRE_DOCUMENT: 'Entire document',
    }
  },
  COPY: {
    TYPE: {
      MATERIAL: 'material link',
      ATTACHMENT: 'attachment link',
      LAB: 'lab link',
      EMAIL: 'email',
      PROFILE: 'profile',
      PHONE: 'phone',
      CASE: 'case link',
      VIGNETTE: 'vignette link',
      KNOWLEDGE_BUNDLE: 'knowledge bundle link',
      KNOWLEDGE_PAGE: 'knowledge page link',
      MICROSITE: 'microsite page link'
    }
  },
  LOCAL_STORAGE: {
    EXPERT_FILTER: 'expert_filter_',
    KNOWLEDGE_FILTER: 'knowledge_filter_',
    PEOPLE_ALUMNI_FILTER: 'people_alumni_filter',
    PEOPLE_VIEW_BY_LASTNAME: 'people_view_by_lastname',
    USER_REGION_NAME: 'hostOfficeRegion',
    USER_LOCATION_NAME: 'hostOfficeLocation',
    SORT_BY: {
      MATERIALS_SERP: 'materialsSERPSortBy',
      CASES_SERP: 'casesSERPSortBy',
      BCG_INTERNAL: 'BCGInternalSERPSortBy',
      EXPERTS_SERP: 'expertsSERPSortBy'
    },
    RESULT_VIEW: 'result_view'
  },
  SESSION_STORAGE: { IS_POP_STATE: 'isPopState' },
  TEST_IDS: {
    BUTTONS: {
      ACTIONS_MENU: 'documentActionsMenuButton',
      ADD_TO_PLAYLIST: 'addToPlaylistButton',
      DOWNLOAD: 'downloadButton',
      EMAIL_ALL: 'emailAllButton',
      MATERIAL_PAGE: 'materialPageButton',
      PREVIEW: 'previewButton',
      PUSHPIN: 'pushpinButton',
      LAUNCH: 'launchButton',
      OPEN_IN_LAB: 'openInLABButton',
      LAB_SHARE: 'labShareButton',
      VISIT_URL: 'visitUrlButton',
      VIEW_MATERIAL_DETAILS: 'viewMaterialDetailsButton',
      RELEVANCY_FEEDBACK: 'relevancyFeedbackButton',
      KN_MATERIALS: 'knMaterialsButton',
      KN_CASES: 'knCasesButton',
      KN_EXPERTS: 'knExpertsButton',
      TOPIC_SUGGESTION: 'topicSuggestionButton',
      TOPIC_PAGER_NEXT: 'topicPagerNext',
      TOPIC_PAGER_PREV: 'topicPagerPrevious',
      EMAIL: 'emailButton',
      PROFILE: 'profileButton',
      SLACK: 'slackButton',
      SHARE: 'shareButton',
      MATERIAL_COPY: 'materialCopyButton',
      ATTACHMENT_COPY: 'attachmentCopyButton',
      CASE_COPY: 'caseCopyButton',
      VIGNETTE_COPY: 'vignetteCopyButton',
      LAB_COPY: 'labCopyButton',
      SEARCH_TUTORIAL: 'searchTutorialButton',
      MENU: 'menuButton',
      CASE_MOBILE_FILTERS: 'casemobilefilters'
    },
    RELEVANCY_FEEDBACK: {
      ANSWERS: 'answers',
      QUESTIONS: 'questions',
      SUBMIT: 'submit',
      CONTENTS: 'contents'
    },
    HOME: {
      SEARCH_TIP: 'searchTip',
      RECENT_ACTIVITY: 'recentActivity'
    },
    LOADER: 'loader',
    NAV: {
      HEADER: 'navHeader',
      BANNER: 'navBanner'
    },
    PINNED_DOCUMENTS: {
      TAB: 'pinnedDocumentsTab',
      FILTER: 'pinnedDocumentsFilter',
      CLEAR: 'pinnedDocumentsClear',
      SELECT: 'pinnedDocumentsSelect'
    },
    RESULTS: {
      MARKET_BASKET_SUGGESTION: 'relatedSuggestion',
      AUTO_CORRECT_MESSAGE: 'resultsAutoCorrectMessage',
      CARD: 'resultCard',
      DISPLAY_CONTROLS: {
        BOX: 'displayControlBox',
        EXPANDED: 'displayControlExpanded',
        GRID: 'displayControlGrid',
        LIST: 'displayControlList',
        SORT_BY: 'displayControlSortBy'
      },
      GO_TO_TOP: 'goToTopButton',
      HEADING: 'resultsHeading',
      AUTHOR: 'author',
      REFINERS: {
        CLEAR_BREAD_CRUMB: 'clearBreadCrumbRefiner',
        CLEAR_ALL: 'clearAllRefiners'
      },
      SHOW_MORE: 'showMoreButton',
      OTHER_ATTACHMENTS: 'otherAttachments',
      RESULT_CONTROLS: {
        RESULT_COUNT_WRAPPER: 'resultCountWrapper',
        APPLIED_FILTERS_COUNT_WRAPPER: 'appliedFiltersCountWrapper',
        RESULT_TOTAL_COUNT: 'materialsTotalCount',
        APPLIED_FILTERS_COUNT: 'appliedFiltersCount',
      }
    },
    SEARCH: {
      SEARCH_TEXT_BOX: 'searchTextBox',
      TYPEAHEAD: 'typeAhead',
      SUGGESTIONS: 'suggestions'
    },
    LINK_REDIRECT: 'linkRedirect',
    DOWNLOAD_PROGRESS: {
      PROGRESS_BUTTON: 'downloadProgressButton',
      PROGRESS_CONTAINER: 'downloadProgressContainer'
    },
    POPUP: 'popup',
    DOC_VIZ: {
      SLIDE_LOADER: 'docVizSlideLoading'
    },
    DROPDOWNLIST: {
      LIST: 'list',
      CHECKBOX: 'checkbox',
      RADIO: 'radio',
      LIST_ITEM: 'listitem',
      SELECT_ALL: 'selectAll',
      FILTERED_COLUMN: 'filteredColumn',
      FILTERED_OPTION: 'filteredOption'
    },
    LAB: { CARD_ACTION: 'labAction' },
    CASE: {
      CASE_CARD: 'caseCard',
      CASE_INFO: {
        CASEINFORMATION: 'caseinformation',
        CASETYPE: 'casetype',
        CASEOPENCLOSEDATES: 'caseopenclosedates',
        CASEICON: 'caseicon',
        CASETEAMMEMBERS: 'caseTeamMembers'
      },
      CASE_DESCRIPTION: {
        CASEDESCRIPTIONWRAPPER: 'casedescriptionwrapper',
        TITLE: 'title',
        CASESHORTDESCRIPTION: 'caseshortdescription',
        CASENUMBERBUTTON: 'casenumberbutton',
        CASENUMBERICON: 'casenumbericon',
        STYLEDPOPUP: 'styledpopup',
        TOPICS: 'topics',
        TOPIC_TAGS: 'topicTags',
        TOPIC: 'topic',
        CLOSE: 'close'
      },
      CASE_CARD_EXPANDED: {
        CASEEXPANSIONWRAPPER: 'caseexpansionwrapper',
        CASEEXPANSIONICON: 'caseexpansionicon',
        CASEEXPANSIONLABEL: 'caseexpansionlabel',
        CASECOLLAPSEWRAPPER: 'casecollapsewrapper',
        CASECOLLAPSEICON: 'casecollapseicon',
        CASECOLLAPSELABEL: 'casecollapselabel',
        CASECARDEXPANDED: 'casecardexpanded',
      },
      FILTERS: {
        ADVANCED_DROPDOWN: 'advancedDropdown',
        DROPDOWN_NAME: 'dropdownName',
        DROPDOWN_MENU_WRAPPER: 'dropdownMenuWrapper',
        DROPDOWN_ACTIONS: 'dropdownActions',
        CARET: 'caret',
        LABEL: 'label',
        BACKDROP: 'backdrop',
        RESULTS_BREADCRUMBS: 'resultsBreadcrumbs',
        APPLY_BUTTON: 'applyButton',
        CLEAR_BUTTON: 'clearButton',
        CLOSE_ICON: 'closeIcon'
      },
      CASE_CONTROLS: {
        RESULT_COUNT_WRAPPER: 'resultCountWrapper',
        APPLIED_FILTERS_COUNT_WRAPPER: 'appliedFiltersCountWrapper',
        CASE_TOTAL_COUNT: 'caseTotalCount',
        APPLIED_FILTERS_COUNT: 'appliedFiltersCount',
      }
    },
    ATTACHMENTS_TABLE: {
      TABLE: 'table',
      HEADER: 'header',
      HEADER_CELL: 'headercell',
      HEADER_CHECKBOX: 'headercheckbox',
      DEFINITION_CHECKBOX: 'definitioncheckbox',
      DOCUMENT_NAME: 'documentname',
      DOWNLOADS: 'downloads',
      DATE: 'date',
      BODY: 'body',
      DEFINITION_CELL: 'definitioncell',
      ACTIONS: 'actions',
      EMAIL_BUTTON: 'emailbutton',
      DOWNLOAD_BUTTON: 'downloadbutton',
      ARROWS: {
        NAME: {
          UP: 'nameup',
          DOWN: 'namedown'
        },
        DATE: {
          UP: 'dateup',
          DOWN: 'datedown'
        },
        DOWNLOADS: {
          UP: 'downloadsup',
          DOWN: 'downloadsdown'
        }
      },
      ATTACHMENT_WRAPPER: 'attachmentwrapper'
    },
    PEOPLE: {
      COUNT_WRAPPER: 'countWrapper',
      CHECKBOX: 'checkbox',
      FILTERS: {
        ADVANCED_DROPDOWN: 'advancedDropdown',
        DROPDOWN_NAME: 'dropdownName',
        DROPDOWN_MENU_WRAPPER: 'dropdownMenuWrapper',
        DROPDOWN_SEARCH: 'dropdownSearch',
        DROPDOWN_ACTIONS: 'dropdownActions',
        CARET: 'caret',
        LABEL: 'label',
        BACKDROP: 'backdrop',
        RESULTS_BREADCRUMBS: 'resultsBreadcrumbs',
        APPLY_BUTTON: 'applyButton',
        CLEAR_BUTTON: 'clearButton',
        INFO_TOOLTIP: 'toolTip'
      }
    },
    TYPEAHEAD: { ITEM: 'typeaheadItem' },
    MATERIAL: {
      FILTERS: {
        ADVANCED_DROPDOWN: 'advancedDropdown',
        DROPDOWN_NAME: 'dropdownName',
        DROPDOWN_MENU_WRAPPER: 'dropdownMenuWrapper',
        DROPDOWN_ACTIONS: 'dropdownActions',
        CARET: 'caret',
        LABEL: 'label',
        BACKDROP: 'backdrop',
        RESULTS_BREADCRUMBS: 'resultsBreadcrumbs',
        APPLY_BUTTON: 'applyButton',
        CLEAR_BUTTON: 'clearButton',
        CLOSE_ICON: 'closeIcon'
      },
      CARD_EXPANDED: {
        MATERIALEXPANSIONWRAPPER: 'materialexpansionwrapper',
        MATERIALEXPANSIONICON: 'materialexpansionicon',
        MATERIALEXPANSIONLABEL: 'materialexpansionlabel',
        MATERIALCOLLAPSEWRAPPER: 'materialcollapsewrapper',
        MATERIALCOLLAPSEICON: 'materialcollapseicon',
        MATERIALCOLLAPSELABEL: 'materialcollapselabel',
        MATERIALCARDEXPANDED: 'materialcardexpanded',
      },
    }
  },
  ADVANCED_SEARCH: {
    TITLE: 'Advanced search',
    INFO_TOOLTIP: 'Search with Boolean operators by separating your search words with “AND”, “OR”, “NOT” or "NEAR"'
  },
  CASE_RESTRICTED: 'restricted',
  BUTTON_TYPE: {
    VIEW_DETAILS: 'view_details',
    CARD_ACTIONS: 'card_actions',
    SLIDE_VIEW: 'slide_view'
  },
  USER_REQUESTS: {
    USE_CASE: {
      TITLE: 'Suggest new use case',
      TO: 'AskKT_TechnologyAdvantage@bcg.com',
      SUBJECT: 'X-Factory New Use Case Suggestion',
      BODY: null
    },
    ADDITIONAL_RESEARCH: {
      TITLE: 'Request additional research',
      TO: 'AskKT_TechnologyAdvantage@bcg.com',
      SUBJECT: 'X-Factory Additional Research Required Request',
      BODY: null
    }
  },
  HELPFUL_REQUESTS: {
    USE_CASE: {
      TITLE: 'Service Offering Request',
      GAMMA: 'GAMMA@bcg.com',
      LEGAL: 'LegalTeam@bcg.com',
      CFS: 'cfsteam@bcg.com',
      CONFLICT: 'ConflictClearance@bcg.com',
      CLIENT: 'ClientClear@bcg.com',
      SUBJECT: 'Service Offering Request',
      BODY: 'Hi Team,',
      BODYR: 'Please find details of the request:',
      BODY1: '1. TITLE/TYPE OF WORK:',
      BODY2: '2. REQUESTED DEADLINE:',
      BODY3: '3. CHARGE CODE:',
      BODY4: '4. INITIAL INSTRUCTIONS:',
      BODY5: '5. CASE DETAILS/CONTEXT:'
    }
  },
  FEATURE_TOGGLES: {
    USE_URL_SHORTENER: false,
    SHOW_KNOWLEDGE_TAB: process.env.REACT_APP_SHOW_KNOWLEDGE_TAB.trim() === 'true',
    SHOW_CASE_TAB: process.env.REACT_APP_SHOW_CASE_TAB.trim() === 'true',
    SHOW_ALL_TYPEAHEADS: process.env.REACT_APP_SHOW_ALL_TYPEAHEADS.trim() === 'true',
    SHOW_ADVANCED_SEARCH: process.env.REACT_APP_SHOW_ADVANCED_SEARCH.trim() === 'true',
    ADVANCED_SEARCH_PAGES: process.env.REACT_APP_ADVANCED_SEARCH_PAGES.split(','),
    PINNED_DOCUMENTS: process.env.REACT_APP_SHOW_PINNEDDOCS.trim() === 'true',
    SHOW_MICROSITES_SEARCH_RESULTS: `${process.env.REACT_APP_SHOW_MICROSITES_SEARCH_RESULTS}`.trim() === 'true',
    SHOW_OFFICEPAGES_SEARCH_RESULTS: `${process.env.REACT_APP_SHOW_OFFICEPAGES_SEARCH_RESULTS}`.trim() === 'true',
    SHOW_HELPFUL_LINKS_SEARCH_RESULTS: `${process.env.REACT_APP_SHOW_HELPFUL_LINKS_SEARCH_RESULTS}`.trim() === 'true',
    SHOW_BCG_INTERNAL_TAB: `${process.env.REACT_APP_SHOW_BCG_INTERNAL_TAB}`.trim() === 'true',
    SHOW_CASE_DOWNLOAD_PPT: `${process.env.REACT_APP_SHOW_CASE_DOWNLOAD_PPT}`.trim() === 'true',
    SHOW_BEST_BETS: `${process.env.REACT_APP_SHOW_BEST_BETS}`.trim() === 'true',
    ENABLE_DOC_VIZ: `${process.env.REACT_APP_DOC_VIZ_FEATURE_VERSION}`.trim() === 'true',
    ENABLE_LIST_FILTER_TYPEAHEAD: `${process.env.REACT_APP_ENABLE_LIST_FILTER_TYPEAHEAD}`.trim() === 'true',
    SHOW_QUERY_SUGGESTIONS: `${process.env.REACT_APP_SHOW_QUERY_SUGGESTIONS}`.trim() === 'true',
    SHOW_EXPERTS_TAB: `${process.env.REACT_APP_SHOW_EXPERTS_TAB}`.trim() === 'true',
    SHOW_PA_TOPICS_TAB: `${process.env.REACT_APP_SHOW_PA_TOPICS_TAB}`.trim() === 'true',
    SHOW_GRID_VIEW: `${process.env.REACT_APP_SHOW_GRID_VIEW}`.trim() === 'true',
    SHOW_CASE_TEAM_SERVICES_BANNER: `${process.env.REACT_APP_SHOW_CASE_TEAM_SERVICES_BANNER}`.trim() === 'true',
    LEFT_LAYOUT: `${process.env.REACT_APP_LEFT_LAYOUT}`.split(','),
    SHOW_AI_VIEW: process.env.REACT_APP_SHOW_AI_VIEW.trim() === 'true',
    AI_VIEW_ENABLED: `${process.env.REACT_APP_AI_VIEW_ENABLED}`.split(','),
    SHOW_AI_CHAT: `${process.env.REACT_APP_SHOW_AI_CHAT}`.split(','),
    SHOW_PROPOSALBUILDER:  process.env.REACT_APP_SHOW_PROPOSALBUILDER.trim() === 'true',
    SHOW_CHAT_HISTORY: process.env.REACT_APP_SHOW_CHAT_HISTORY.trim() === 'true',
    ENABLE_CHAT_HISTORY_ACTIONS: process.env.REACT_APP_ENABLE_CHAT_HISTORY_ACTIONS.trim() === 'true',
  },
  MAINTENANCE_FLAG: 'maint',
  KEYCODES: {
    ENTER: 13,
    ESCAPE: 27,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40
  },
  REGION_MAPPING: [
    {
      NAME: 'North America',
      ABBREVIATION: 'NAMR'
    },
    {
      NAME: 'CEMA',
      ABBREVIATION: 'CEMA'
    },
    {
      NAME: 'Asia and Pacific',
      ABBREVIATION: 'AP'
    },
    {
      NAME: 'WESA',
      ABBREVIATION: 'WESA'
    },
    {
      NAME: 'EMESA',
      ABBREVIATION: 'EMESA'
    }
  ],
  DOWNLOAD_PROGRESS: {
    MODAL_HEADER_TEXT: 'Preparing your download(s)...',
    REFRESH_WARNING_TEXT: 'Please do not refresh your browser or leave this page until your download is complete.',
    ERROR_TOOLTIP_TEXT: 'Unable to prepare file for download',
    DOWNLOAD_FAILED_TITLE: 'Error preparing your download',
    DOWNLOAD_FAILED_TEXT: 'Sorry, we were unable to prepare your download, please try again.',

    STATUS_CODES: {
      NEW: 'NEW',
      CREATION: 'CREATION',
      INPROGRESS: 'INPROGRESS',
      PENDING: 'PENDING',
      SUCCESS: 'SUCCESS',
      COMPLETE: 'COMPLETE',
      ERROR: 'ERROR',
    }
  },
  PROPOSAL_DROPDOWN_OPTION: {
    PROPOSALBUILDER: {
      KEY: 'Proposal Agent',
      TEXT: 'Proposal Agent',
      VALUE: 'proposalbuilder',
    },
    NAVI: {
      KEY: 'Navi',
      TEXT: 'Navi',
      VALUE: 'navi',
    }
  },
  GPT_RESTRICTED_LOCATIONS: process.env.REACT_APP_GPT_RESTRICTED_LOCATIONS?.split(','),
  EXPORT_OPTIONS: {
    PDF: {
      KEY: 1,
      TEXT: 'Export as PDF',
      VALUE: 1,
    },
    DOC: {
      KEY: 2,
      TEXT: 'Export as DOC',
      VALUE: 2,
    }
  }
};


export default CONFIG;
