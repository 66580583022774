import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUserAuthenticated } from 'utils/auth/auth';
import { actions as caseActions } from 'redux/api/casesv2Search/casesv2Search';
import { name as searchName, actions as searchActions } from 'redux/api/search/search';
import { actions as userActions } from 'redux/api/user/user';
import ANALYTICS from 'utils/analytics/analytics';

const AppInit = ({ pageName }) => {
  const dispatch = useDispatch();
  const { authorizeCaseUser } = caseActions;
  const { profiles } = useSelector((state) => state[searchName]);

  useEffect(() => {
    const loadApp = async () => {
      if (await isUserAuthenticated()) {
        dispatch(userActions.getUserRegionFromApi());
        dispatch(userActions.fetchUserProfilePhoto());
        dispatch(authorizeCaseUser());
        if (profiles.length === 0) {
          dispatch(searchActions.searchUserConfigProfile());
        }
        ANALYTICS.page.initialize(pageName, 'Page', true);
      }
    };

    loadApp();
  }, []);

  return (<></>);
};

export default AppInit;