const bcggreenblue = '#3FAD93';
const bcgred = '#D50000';
const white = '#FFFFFF';
const veryDarkGrayMostlyBlack = '#111111';
const lightGray = '#D4D4D4';
const lightGrayishLimeGreen = '#DCF9E3'; /* Primary/Green200 */

const NEW = {
  veryLightGrayMostlyWhite: '#F8F8F8',
  veryLightGrayMostlyWhite2: '#F4F4F4',

  veryLightGray: '#DDDDDD',
  veryLightGray2: '#D9D9D9',

  lightGray,
  lightGray2: '#CACACA',

  veryDarkGrayMostlyBlack,

  darkModerateCyan: bcggreenblue,
  darkModerateCyan2: '#3FA0AD',

  verySoftLimeGreen: '#A8F0B8',
  moderateCyan_limeGreen: '#56C989',
  moderateCyan_limeGreen2: '#4FBE79',
  strongCyan_limeGreen: '#21BF61',
  veryDarkCyan_limeGreen: '#0E3E1B',
  pureOrMostlyPureYellow: '#FFFF00',
  lightGrayishLimeGreen, // Primary/Green200

  closeicon: '#737373',
  careticon: '#B1B1B1',
  border: lightGray,
  darkgreen: '#197A56',

  darkertan: '#C4B5A4',
  darktan: '#DFD7CD',
  tan: '#856E57',
  lighttan: '#F1EEEA',

  yellow: '#FFE68F',
  lighttext: '#535353',
  filtercount: lightGrayishLimeGreen,
  babyblue: '#E0F1FF',

  // using design system naming
  primary: {
    accent: '#7EF473'
  },
  secondary: {
    blue500: '#0A477D',
    blue500at60p: 'rgb(224, 241, 255, 0.6)'
  },
  grays: {
    gray100: '#F6F6F6',
    gray200: '#F2F2F2',
    gray300: '#D4D4D4',
    gray400: '#B1B1B1',
    gray500: '#535353',
    gray700: '#323232',
    gray900: '#232326',
  },
  reds: {
    red200: '#FCE1DC',
    red200at60p: 'rgba(252, 225, 220,0.6)'
  },
  white: '#FFFFFF',
  black: '#000000',
  chat: {
    leftMargin: '#1f2023',
    leaveChat: '#1f2023',
  }
};

const COLORS = {
  // Primary brand colors
  $bcggreendark: '#00532F',
  $bcggreen: '#177B57',
  $bcggreenbluegreen: '#3EAD93',
  $bcggreenblue: bcggreenblue,
  $bcggreenlight: '#32C77F',
  $bcgyellow: '#E4EF39',
  $bcgred: bcgred,
  $bcgblue: '#00BCD4',
  $bcgbluelight: '#88C0FE',
  $bcgbluedark: '#1E455E',
  $bcgorange: '#FF7002',

  $bcggold: '#A8B21C',
  $bcgmaroon: '#670F31',
  $grey: '#D4D4D4',
  $black200: '#1f2023',

  //Orangescale
  $orange: '#F48067',

  // Grayscale
  $black: '#000000',
  $graydarkest: '#333333',
  $graydarker: '#707070',
  $graydark: '#B7B7B7',
  $graydarklight: '#D8D8D8',
  $gray: '#E6E6E6',
  $gray2: '#BEBEBE',
  $graylight: '#F2F2F2',
  $graylighter: '#FAFBFB',
  $graylightest: '#FAFAFA',
  $white: white,
  $tabmenu: '#F5F5F5',
  $bggray: '#D4D4D4CC',
  $btngray: '#202123',
  $transparent: 'rgba(0, 0, 0, 0)',
  $checkboxgray: '#979797',

  //notifications
  $notifyerror: '#FAE6E6',
  $notifyerrorhighlight: bcgred,
  $notifyinfo: '#EAF7F9',
  $notifyinfohighlight: bcgred,
  $notifysuccess: '#EDF5D7',
  $notifysuccesshighlight: '#4CAF50',
  $notifywarn: '#FFF6D0',
  $notifywarnhighlight: '#FF9800',

  //rating
  $ratingabsent: '#ECE3E3',
  $ratingpresent: '#F8CE0B',

  //modal-color
  $modalwhite: `rgba(${white}, 0.95)`,

  $brightYellow: '#F1FE39',
  $gvgray: '#323232',
  $gvreadmore: '#6E6E73',
  $gvbutton: veryDarkGrayMostlyBlack,
};

const FONTS = {
  $sansreg: 'HendersonBCGSans-Regular, Arial, Helvetica, sans-serif',
  $sansbold: 'HendersonBCGSans-Bold, Arial, Helvetica, sans-serif',
  $sanslight: 'HendersonBCGSans-Light, Arial, Helvetica, sans-serif'
};

const SIZE = (pixel) => `${pixel / 16}rem`;

const BREAKPOINTS = {
  md: '667px', // 667 iphone landscape, ipad in portrait (768)
  lg: '1024px', // 1024 ipad in landscape
  xl: '1168px' // 1168 max width, laptop
};

const MEDIA_QUERIES = {
  LG: '@media (min-width: 1024px)', // ipad in landscape, max width
  MD: '@media (min-width: 557px) and (max-width: 767px)', // iphone landscape, ipad in portrait
  SM: '@media (min-width: 768px) and (max-width:991px)',
  SM_LAPTOP: '@media (max-height: 800px)'
  // XL: '@media (min-width: 1168px)' // max width, laptop
};

//shadow details
const SHADOWS = { // maybe name this a more generic styleing name? a catch all for other stuff like this
  1: 'box-shadow: 0 1px 1px 0 rgba(0,0,0, 0.14), 0 2px 1px -1px rgba(0,0,0, 0.12), 0 1px 3px 0 rgba(0,0,0, 0.2);',
  2: 'box-shadow: 0 6px 10px 0 rgba(0,0,0, 0.14), 0 1px 18px 0 rgba(0,0,0, 0.12), 0 3px 5px -1px rgba(0,0,0, 0.2);',
  3: '0 2px 6px 0 #F2F2F2',
  4: '-3px 3px 11px 3px #B7B7B7',
  5: '6px 3px 11px 3px #B7B7B7',
  6: '0 2px 4px 0 rgba(51,51,51,0.4)',
  7: '0 0 20px 0 #F2F2F2',
  8: '0 9px 11px 3px #B7B7B7',
  9: '0 0 3px 0 #707070',
  10: '0 1px 3px 0 rgba(0,0,0,0.2)'
};

// add in order of z-index
const ZINDEX = {
  FILTERS_BACKDROP: 1,
  CUSTOM_DATE: 2,
  TEAM_MEMBERS: {
    BASE: 0,
    FIRST: 10,
    SECOND: 9,
    THIRD: 8,
    FOURTH: 7
  },
  CARD: { TOPIC_PATH_CONTAINER: 100 },
  ADVANCED_DROPDOWN: 1001,
  SERP_LOADING: 1005,
  ADVANCED_SEARCH_PANE: 1010,
  TYPEAHEAD: 1500,
  FILTER_MENU: 1510,
  POPUP: 2121,
};

export default {
  BREAKPOINTS,
  COLORS,
  NEW,
  FONTS,
  MEDIA_QUERIES,
  SHADOWS,
  SIZE,
  ZINDEX
};
