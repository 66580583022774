import CONFIG from 'config';
import { getFromLocalStorage } from 'utils/localStorage/localStorage';

export const name = 'serpTabs';

export const SERP_TAB_SET_IS_AI_VIEW = 'SERP_TAB_SET_IS_AI_VIEW';
export const SERP_TAB_SELECTED_INDEX = 'SERP_TAB_SELECTED_INDEX';

const initialState = {
  selectedIndex: null,
  isAiView: false
};

export const actions = {
  setSelectedIndex: (selectedIndex) => (dispatch) => dispatch({ type: SERP_TAB_SELECTED_INDEX, payload: { selectedIndex } }),
  setIsAiView: (isAiView) => (dispatch) => dispatch({ type: SERP_TAB_SET_IS_AI_VIEW, payload: { isAiView }})
};

export function reducer(state = initialState, action) {
  const params = new URLSearchParams(window.location.search);
  const resultsViewParams = decodeURIComponent(params.get('resultsView'));
  const localStorageResultView = getFromLocalStorage(CONFIG.LOCAL_STORAGE.RESULT_VIEW);
  const aiViewName = CONFIG.DISPLAY_TEXT.DISPLAY_CONTROL_TOOLTIPS.AI_VIEW;
  const newViewIsAiView = resultsViewParams !== 'null' ? resultsViewParams === aiViewName : localStorageResultView === aiViewName;
  
  switch (action.type) {
    case SERP_TAB_SELECTED_INDEX:
      return {
        ...state,
        previousIndex: state.selectedIndex,
        selectedIndex: action.payload.selectedIndex,
        isAiView: newViewIsAiView
      };
    case SERP_TAB_SET_IS_AI_VIEW:
      return {
        ...state,
        isAiView: action.payload.isAiView
      };
    default:
      return state;
  }
}