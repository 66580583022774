export const name = 'downloads';

export const types = { UPDATE_DOWNLOADS: 'UPDATE_DOWNLOADS', };

const initialState = { currentDownloads: [], };
/* sample download item
{
    fileId: guid
    fileName: string,
    downloadType: string, differentiate what download type it is
    downloadProgressLink,
}
*/
export const selectors = { getCurrentDownloads: (state) => state[name].currentDownloads, };

export const actions = {
  addDownload: (newDownload) => async (dispatch, getState) => {
    const state = getState()[name];
    const updatedDownloads = [...state.currentDownloads, newDownload];
    dispatch({ type: types.UPDATE_DOWNLOADS, payload: updatedDownloads });
  },
  removeDownload: (value, propName) => async (dispatch, getState) => {
    const state = getState()[name];
    const updatedDownloads = state.currentDownloads.filter((download) => download[propName] !== value);
    dispatch({ type: types.UPDATE_DOWNLOADS, payload: updatedDownloads });
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_DOWNLOADS:
      return { ...state, currentDownloads: action.payload, };
    default:
      return state;
  }
};